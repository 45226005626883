import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { environment } from '../../../../environments/environment';

export interface SubscriptionSelectionDialogConfig {
  /**
   * combined auth-user-id_project-id
   *
   * @type {string}
   * @memberOf SubscriptionSelectionDialogConfig
   */
  clientReferenceId: string;
  email: string;
  projectId: string;
  pricingTableId?: string;
}
@Component({
  selector: 'eule-subscription-selection-dialog',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './subscription-selection-dialog.component.html',
  styleUrl: './subscription-selection-dialog.component.scss',
})
export class SubscriptionSelectionDialogComponent implements OnInit {
  public publishableKey = environment.stripeConfig.publishableKey;

  stripe: Stripe | null = null;

  public constructor(@Inject(MAT_DIALOG_DATA) public dialogData: SubscriptionSelectionDialogConfig) {}

  async ngOnInit() {
    this.stripe = await loadStripe(this.publishableKey);
  }
}
