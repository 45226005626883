import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from '../../../../environments/environment';

export interface ExportProjectInfoGeneralAsPdfData {
  projectId: string;
  fileName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(private _http: HttpClient) {}

  /**
   * Downloads the Data as PDF
   *
   * @returns
   *
   * @memberOf PdfService
   */
  public downloadProjectInfoGeneral(requestData: ExportProjectInfoGeneralAsPdfData) {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callExportProjectInfoGeneralAsPdf';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;

    return this._http.post(functionUrl, requestData, { responseType: 'blob' }).pipe(
      tap(blob => {
        // Erzeuge eine URL für den Blob-Inhalt
        const url = window.URL.createObjectURL(blob);

        // Erstelle einen temporären Link
        const a = document.createElement('a');
        a.href = url;
        a.download = requestData?.fileName ? requestData.fileName : 'eeule-Projektinfo-Allgemein.pdf';
        document.body.appendChild(a);
        a.click();

        // Link entfernen und Speicher freigeben
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
    );
  }

  /**
   * Downloads the Data as PDF
   *
   * @returns
   *
   * @memberOf PdfService
   */
  public downloadProjectInfoBuildingDescription(requestData: ExportProjectInfoGeneralAsPdfData) {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callExportProjectInfoBuildingDescriptionAsPdf';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;

    return this._http.post(functionUrl, requestData, { responseType: 'blob' }).pipe(
      tap(blob => {
        // Erzeuge eine URL für den Blob-Inhalt
        const url = window.URL.createObjectURL(blob);

        // Erstelle einen temporären Link
        const a = document.createElement('a');
        a.href = url;
        a.download = requestData?.fileName ? requestData.fileName : 'eeule-Projektinfo-Baubeschreibung.pdf';
        document.body.appendChild(a);
        a.click();

        // Link entfernen und Speicher freigeben
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
    );
  }

  /**
   * Downloads the Data as PDF
   *
   * @returns
   *
   * @memberOf PdfService
   */
  public downloadProjectInfoLeistungsphasen(requestData: ExportProjectInfoGeneralAsPdfData) {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callExportProjectInfoLeistungsphasenAsPdf';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;

    return this._http.post(functionUrl, requestData, { responseType: 'blob' }).pipe(
      tap(blob => {
        // Erzeuge eine URL für den Blob-Inhalt
        const url = window.URL.createObjectURL(blob);

        // Erstelle einen temporären Link
        const a = document.createElement('a');
        a.href = url;
        a.download = requestData?.fileName ? requestData.fileName : 'eeule-Projektinfo-Leistungsphasen.pdf';
        document.body.appendChild(a);
        a.click();

        // Link entfernen und Speicher freigeben
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
    );
  }

  /**
   * Downloads the Data as PDF
   *
   * @returns
   *
   * @memberOf PdfService
   */
  public downloadProjectInfoUsageProfiles(requestData: ExportProjectInfoGeneralAsPdfData) {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callExportProjectInfoUsageProfilesAsPdf';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;

    return this._http.post(functionUrl, requestData, { responseType: 'blob' }).pipe(
      tap(blob => {
        // Erzeuge eine URL für den Blob-Inhalt
        const url = window.URL.createObjectURL(blob);

        // Erstelle einen temporären Link
        const a = document.createElement('a');
        a.href = url;
        a.download = requestData?.fileName ? requestData.fileName : 'eeule-Projektinfo-Nutzungsprofile.pdf';
        document.body.appendChild(a);
        a.click();

        // Link entfernen und Speicher freigeben
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
    );
  }

  /**
   * Downloads the Data as PDF
   *
   * @returns
   *
   * @memberOf PdfService
   */
  public downloadProjectInfoCertification(requestData: ExportProjectInfoGeneralAsPdfData) {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callExportProjectInfoCertificationAsPdf';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;

    return this._http.post(functionUrl, requestData, { responseType: 'blob' }).pipe(
      tap(blob => {
        // Erzeuge eine URL für den Blob-Inhalt
        const url = window.URL.createObjectURL(blob);

        // Erstelle einen temporären Link
        const a = document.createElement('a');
        a.href = url;
        a.download = requestData?.fileName ? requestData.fileName : 'eeule-Projektinfo-Zertifizierung.pdf';
        document.body.appendChild(a);
        a.click();

        // Link entfernen und Speicher freigeben
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
    );
  }

  /**
   * Downloads the Data as PDF
   *
   * @returns
   *
   * @memberOf PdfService
   */
  public callExportProjectInfoBuildingParametersAsPdf(requestData: ExportProjectInfoGeneralAsPdfData) {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callExportProjectInfoBuildingParametersAsPdf';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;

    return this._http.post(functionUrl, requestData, { responseType: 'blob' }).pipe(
      tap(blob => {
        // Erzeuge eine URL für den Blob-Inhalt
        const url = window.URL.createObjectURL(blob);

        // Erstelle einen temporären Link
        const a = document.createElement('a');
        a.href = url;
        a.download = requestData?.fileName ? requestData.fileName : 'eeule-Projektinfo-Gebäudeparameter.pdf';
        document.body.appendChild(a);
        a.click();

        // Link entfernen und Speicher freigeben
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
    );
  }
}
