<ng-container [formGroup]="certificationDescriptionFormGroup">
  <div class="row tab-content">
    <h2>{{ title }}</h2>
  </div>

  <div class="row group-dimension">
    <div class="col padding-right">
      <ng-content></ng-content>
    </div>
    <div class="col padding-right">
      <h3>Erforderliche Nachweise</h3>
      <ng-container formArrayName="attestations">
        @for (attestation of attestations.controls; track attestation) {
          <ng-container [formGroup]="getAttestationGroupAt($index)">
            <mat-checkbox formControlName="checked" color="primary"
                          [attr.data-cy]="'checkbox-tab-certificationDescription-attestation-' + $index">
              <span class="secondary-text">{{ getAttestationDescriptionAt($index) }}</span>
            </mat-checkbox>
          </ng-container>
        }
      </ng-container>

      <div class="attachments flexbox direction-column">
        <div class="label default-border-color primary-text flexbox small-gap align-center">
          <div class="attachments-title">Anhänge</div>
          <div [euleTooltip]="_permissionService.hasRights('project_create_document')
                    ? 'Anhang zu Gruppe hinzufügen'
                    : 'Sie besitzen nicht die benötigten Rechte,</br> um Anhänge hinzuzufügen.'"
               [htmlTooltipContent]="true"
               [tooltipPositionOffsetY]="6">
              <button
                mat-mini-fab
                color="accent"
                class="no-shadow smaller-button"
                [disabled]="!_permissionService.hasRights('project_create_document')"
                (click)="onOpenAddAttachmentDialog()"
                data-cy="button-tab-certificationDescription-addAttachment"
              >
                <mat-icon>add</mat-icon>
              </button>
          </div>
        </div>
        <div class="row">
          @for (document of groupDocuments$ | async; track document) {
            <eule-document-tile [documentId]="document.id"
                                [attr.data-cy]="'document-tile-tab-certificationDescription' + $index"></eule-document-tile>
          }
        </div>
      </div>
    </div>
  </div>
</ng-container>
