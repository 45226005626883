<!-- TOTAL Parameters of all UsageProfiles -->
<ng-container [formGroup]="buildingParametersFormGroup">
  <div class="row">
    <div class="col">
      @if(getTotalform()) {
      <ng-container [formGroup]="getTotalform()!">
        <h2 class="bordered default-border-color">Gesamt Nutzungsprofile</h2>
        <div class="row flexbox align-center xs-gap">
          <h4>Flächennutzung nach DIN277</h4>
          <mat-icon fontIcon="info"
                    fixedWidth="300px"
                    tooltipPosition="bottom"
                    [htmlTooltipContent]="true"
                    [euleTooltip]="tooltips['flaechenberechnungNachDin277']"></mat-icon>
        </div>
        <div class="row">
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>BGF (R)</mat-label>
            <input matInput formControlName="bgf" data-cy="input-tab-buildingParameters-bgf" />
          </mat-form-field>
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>BRI</mat-label>
            <input matInput formControlName="bri" data-cy="input-tab-buildingParameters-bri" />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>NRF (R)</mat-label>
            <input matInput formControlName="nrf" data-cy="input-tab-buildingParameters-nrf" />
          </mat-form-field>
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>NUF (R)</mat-label>
            <input matInput formControlName="nuf" data-cy="input-tab-buildingParameters-nuf" />
          </mat-form-field>
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>TF (R)</mat-label>
            <input matInput formControlName="tf" data-cy="input-tab-buildingParameters-tf" />
          </mat-form-field>
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>VF (R)</mat-label>
            <input matInput formControlName="vf" data-cy="input-tab-buildingParameters-vf" />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>KGF (R)</mat-label>
            <input matInput formControlName="kgf" data-cy="input-tab-buildingParameters-kgf" />
          </mat-form-field>
        </div>
        <div class="row flexbox align-center xs-gap">
          <h3>DGNB-Bemessungsfläche</h3>
          <mat-icon fontIcon="info"
                    fixedWidth="400px"
                    [tooltipPositionOffsetY]="4"
                    [htmlTooltipContent]="true"
                    [euleTooltip]="tooltips['bemessungsflaeche']"></mat-icon>
        </div>
        <div class="row">
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>ADGNB (R)</mat-label>
            <input matInput formControlName="adgnb" data-cy="input-tab-buildingParameters-adgnb" />
          </mat-form-field>
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>NF Fahrzeug</mat-label>
            <input matInput formControlName="nfVehicle" data-cy="input-tab-buildingParameters-nfVehicle" />
          </mat-form-field>
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>VF Hallen,Flure</mat-label>
            <input matInput formControlName="vfHallCorridor" data-cy="input-tab-buildingParameters-vfHallCorridor" />
          </mat-form-field>
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>Anteil in %</mat-label>
            <input matInput formControlName="percentage" data-cy="input-tab-buildingParameters-percentage" />
          </mat-form-field>
        </div>
      </ng-container>
      }
    </div>
    <div class="col">
      <h2 class="bordered default-border-color">Allgemein</h2>
      <div class="spacer"></div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>Anzahl Geschosse</mat-label>
          <eule-comma-decimal-input formControlName="numberOfFloors"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>Anzahl Untergeschosse</mat-label>
          <eule-comma-decimal-input formControlName="numberOfUndergroundFloors"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>Anzahl PKW-Stellplätze</mat-label>
          <eule-comma-decimal-input formControlName="numberOfParkingSpaces"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>Anzahl Wohneinheiten</mat-label>
          <eule-comma-decimal-input formControlName="numberOfResidentialUnits"></eule-comma-decimal-input>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>Anzahl Arbeitsplätze</mat-label>
          <eule-comma-decimal-input formControlName="numberOfWorkspaces"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>Anzahl Nutzer</mat-label>
          <eule-comma-decimal-input formControlName="numberOfUsers"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>BWZ-Nr.</mat-label>
          <input matInput formControlName="bwzNr" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>LCA-Klasse</mat-label>
          <input matInput formControlName="lcaClass" />
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>Art der Gewerbeflächen</mat-label>
          <input matInput formControlName="typeOfCommercialSpaces" data-cy="input-tab-buildingParameters-typeOfCommercialSpaces" />
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- UsageProfiles Parameters -->
  <ng-container formArrayName="usageProfiles">
    @for (profile of usageProfiles.controls; track profile) {
    <eule-usage-profile-parameters
      [usageProfileFormGroup]="getUsageProfilesFormGroupAt($index)"
      (usageProfileChange)="usageProfileChange($index)"
    ></eule-usage-profile-parameters>
    }
  </ng-container>
</ng-container>
