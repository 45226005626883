<div class="center">
  <div class="container col">
    <h3>Passwort ändern</h3>
    <!-- Spalte 1 -->
    <div [formGroup]="currentPasswordForm" [@shake]="shakeService.animation" class="col">
      <mat-form-field>
        <mat-label>aktuelles Passwort</mat-label>
        <input matInput #preCheckClp formControlName="currentPassword" type="password" data-cy="input-profile-security-currentPassword" />
      </mat-form-field>
    </div>
    <button
      mat-raised-button
      color="primary"
      class="no-shadow"
      [disabled]="currentPasswordForm.invalid || !_permissionService.hasRights('global_update_users_own')"
      (click)="reAuthenticate()"
      data-cy="button-profile-security-reauthenticate"
    >
      Authentifizieren
    </button>
  </div>

  <div class="container col">
    <h3>Datenschutzpräferenzen</h3>
    <div class="col">
      <form class="sign-up-form flexbox direction-column" [formGroup]="dataTrackingForm" (ngSubmit)="sendDataTrackingForm()">
        <mat-label>Unpersonalisierte Analytics</mat-label>
        <mat-checkbox formControlName="GA_baselineReports" class="notice-check" color="primary" data-cy="checkbox-profile-security-GA_baselineReports">
          <span class="secondary-text"
            >Für die technische Verbesserung unseres Produktes tracken wir Ihr Nutzerverhalten mit dem Dienst Analytics for Firebase und Google Analytics,
            sofern Sie hiermit einverstanden sind. Sie können den Einsatz auch verweigern und hier jederzeit widerrufen. Bitte entnehmen Sie unserer
            <a class="privacy accent" href="https://www.eeule.de/eeule-software-richtlinien/eeule-software-dse/" target="_blank">Datenschutzerklärung</a> die
            entsprechenden Regelungen hierzu.</span
          >
        </mat-checkbox>
      </form>
    </div>
    <button
      mat-raised-button
      color="primary"
      class="no-shadow"
      [disabled]="dataTrackingForm.invalid || dataTrackingForm.pristine"
      (click)="sendDataTrackingForm()"
      data-cy="button-profile-security-save"
    >
      Datenschutzpräferenzen Speichern
    </button>
  </div>
</div>
