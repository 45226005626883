<div *ngIf="tooltip?.length"
     [ngClass]="{'no-wrap': noWrap}"
     class="eule-tooltip-callout"
     [ngStyle]="{ 'left.px': left, top: top, bottom: bottom }"
     [style]="'width: '+fixedWidth">
  <div [ngClass]="tooltipPosition === 'top' ? 'bottom-arrow' : 'top-arrow'"
       class="eule-tooltip-beak overlay-background-2 primary-text"></div>
  <div class="eule-tooltip-curtain overlay-background-2"></div>
  <div *ngIf="useHtml" id="safeHtmlTooltip" [innerHTML]="tooltip! | safeHtml"
       class="eule-tooltip html-tooltip overlay-background-2 primary-text"></div>
  <div *ngIf="!useHtml" id="unsafeHtmlTooltip" class="eule-tooltip html-tooltip overlay-background-2 primary-text">
    {{ tooltip }}
  </div>
</div>
