import { OverlayContainer } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, HostBinding, inject, Injector, OnInit } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { SnackbarService } from './core/services/snackbar.service';
import { ThemeService } from './core/services/theme.service';
import { Theme, themes } from './types/theme-types';

export const EULE_GERMAN_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'eule-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MatSlideToggleModule, MatNativeDateModule],
  templateUrl: './app.component.html',
  providers: [
    /**
     * TODO (discuss): commented this out, so that the browser can dynamically choose the language
     * via MatNativeDateModule and DateAdapter
     * provided in app.config.ts:
     * { provide: MAT_DATE_LOCALE, useFactory: () => navigator.language }
     */
    // { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: EULE_GERMAN_FORMATS },
  ],
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('class') className = '';

  themeService: ThemeService = inject(ThemeService);
  title = 'eule';

  private _injector = inject(Injector);

  constructor(private overlayContainer: OverlayContainer, private _snackbarService: SnackbarService, private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // this.analyticsService.sendPageView(event.urlAfterRedirects);
      }
    });

    // handle material theme
    const theme$ = toObservable(this.themeService.themeSig, {
      injector: this._injector,
    });
    theme$.subscribe((theme: Theme) => {
      themes.forEach(t => {
        this.overlayContainer.getContainerElement().classList.remove(t);
      });
      this.overlayContainer.getContainerElement().classList.add(theme);
    });
  }
}
