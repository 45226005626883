import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from '../../services/snackbar.service';
import { Pagination } from '@eeule/eeule-shared';
import { UserService } from '../../services/user.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'eule-mat-table-base',
  standalone: true,
  imports: [],
  templateUrl: './mat-table-base.component.html',
  styleUrl: './mat-table-base.component.scss',
})
export class MatTableBaseComponent<T> implements AfterViewInit {
  /**
   * ViewChild property for the MatPaginator component.
   *
   * @type {MatPaginator | undefined}
   */
  @ViewChild(MatPaginator) paginator?: MatPaginator;

  /**
   * ViewChild property for the MatSort component.
   *
   * @type {MatSort | undefined}
   */
  @ViewChild(MatSort) sort?: MatSort;

  /**
   * Data source for the table containing project user display data.
   *
   * @type {MatTableDataSource<ProjectUserDisplay>}
   */
  public dataSource: MatTableDataSource<T & { id: string }> = new MatTableDataSource<T & { id: string }>();

  public rowSelection: SelectionModel<string> = new SelectionModel<string>(true, []);

  constructor(
    public _userService: UserService,
    public _snackbarService: SnackbarService,
    public _cdr: ChangeDetectorRef,
  ) {
  }

  ngAfterViewInit() {
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }

    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }

    this._cdr.detectChanges();
  }

  /**
   * Applies the filter to the data source and resets the paginator to the first page.
   *
   * @param {string} _filterValue - The filter value to apply.
   * @throws {Error} If the data source or paginator is not initialized.
   */
  applyFilter(_filterValue: string) {
    if (!this.dataSource?.paginator) {
      throw new Error('No DataSource for Table');
    }
    this.dataSource.paginator.firstPage();
    this.dataSource.filter = _filterValue.trim().toLowerCase();
  }

  toggleAllRowsSelection() {
    if (this.isAllRowsSelected) {
      this.rowSelection.clear();
    } else {
      this.dataSource.data.forEach((row: T & { id: string }) => this.rowSelection.select(row.id));
    }
  }

  get isAllRowsSelected() {
    return this.rowSelection.selected.length === this.dataSource.data.length;
  }

  /**
   * Updates the user's pagination settings.
   *
   * @param {Pagination | number} pageSize - The new pagination settings / page size.
   */
  onChangePageSize(pageSize: Pagination | number) {
    this._userService.updateUser(this._userService.euleUser$.value!.id, { pagination: pageSize as Pagination });
  }
}
