@if (fieldRows) {
  <mat-card class="custom-card w-100-percent h-100-percent overflow-hidden">
    @if (title) {
      <mat-card-header>
        <mat-card-title class="card-title"> {{ title }}</mat-card-title>
      </mat-card-header>
    }
    <mat-card-content class="card-content h-100-percent">
      <div class="row-wrapper flexbox direction-column large-gap justify-spaced h-100-percent">
        @for (row of fieldRows; track row) {
          <div class="field-row flexbox align-start small-gap">
            @for (field of row; track field) {
              <div class="field flexbox direction-column align-start justify-start xs-gap"
                   [style]="{'width': columnWidth || 100/columnCount + '%'}">
                <div class="field-label strong">{{ field.label }}</div>
                @if (field.isLeafIconArray) {
                  <eule-leaf-icon-list [leafIcons]="getValueAsLeafIconData(field.value)" [visibleLeafs]="8" />
                } @else if (field.useHTML) {
                  <div class="field-value strong accent" [innerHTML]="field.value"></div>
                } @else {
                  <div class="field-value strong accent">{{ field.value }}</div>
                }
              </div>
            }
          </div>
        }
      </div>
    </mat-card-content>
  </mat-card>
}
