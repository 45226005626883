import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { ProjectService } from '../../services/project.service';
import { Project } from '@eeule/eeule-shared';

export const _loadProjectGuard: CanActivateFn = (data: ActivatedRouteSnapshot): Observable<boolean> => {
  const router = inject(Router);
  const _projectService = inject(ProjectService);
  const projectId: string = data.params['id'];

  if (projectId) {
    return _projectService.getProjectById(projectId).pipe(
      map(docSnapshot => {
        if (!docSnapshot.exists) {
          router.navigate(['/intern/projects']);
          return false; // Projekt nicht gefunden, Navigation verhindern
        }

        const projectData: Project = docSnapshot.data() as Project;
        if (projectData && projectData.softDeleted) {
          router.navigate(['/intern/projects']);
          return false; // Projekt hat das Feld softDelete, Navigation verhindern
        }

        _projectService.initProjectListeners(projectId);

        return true; // Erlaubt die Navigation
      })
    );
  }

  router.navigate(['/intern/projects']);
  return of(false);
};
