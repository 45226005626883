<div id="userListPage">
  <eule-general-title [title]="'Benutzerliste'">
    <div class="flexbox smaller-gap align-center">
      <div
        [euleTooltip]="
          _permissionService.hasRights('project_invite_user') ? null : 'Sie besitzen nicht die benötigten Rechte, um diese Projektinformation zu exportieren.'
        "
        [noWrap]="true"
        [tooltipPositionOffsetY]="6"
      >
        <button
          mat-raised-button
          color="primary"
          class="no-shadow"
          [disabled]="!_permissionService.hasRights('project_invite_user')"
          (click)="openAddUserDialog()"
          data-cy="button-users-list-page-add"
        >
          <mat-icon>add</mat-icon>
          Benutzer
        </button>
      </div>

      <div class="v-divider"></div>

      <eule-search-bar id="search-field" [(search)]="searchValue" (searchChange)="search($event)"></eule-search-bar>
    </div>
  </eule-general-title>

  <mat-card class="custom-card overflow-hidden maxh-100-percent">
    <mat-card-content class="small-padding project-user-tabs-card-content overflow-hidden h-100-percent">
      @if (isLoading$ | async) {

      <div class="loading-overlay covering h-100-percent card-background">
        <mat-spinner diameter="50"></mat-spinner>
      </div>

      } @else {

      <mat-tab-group
        [selectedIndex]="selectedTabIndex"
        (selectedIndexChange)="selectedTabIndex = $event"
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        class="project-user-tabs h-100-percent"
      >
        <mat-tab id="projectUsers" data-cy="tab-project-users">
          <ng-template mat-tab-label>
            <div class="tab-label">Allgemein</div>
          </ng-template>
          <div class="tab-content">
            <eule-users-list
              [users]="projectUsers"
              [filter]="searchValue"
              (deleteUser)="deleteUserFromProject($event)"
              (deactivateUser)="toggleUserStatus($event)"
            />
          </div>
        </mat-tab>

        @if (_permissionService.hasRights('project_view_license_administration')) {
        <mat-tab id="licenseAdministration" data-cy="tab-license-administration">
          <ng-template mat-tab-label>
            <div class="tab-label">Lizenzverwaltung</div>
          </ng-template>
          <div class="tab-content">
            <eule-license-administration
              [users]="projectUsers"
              [filter]="searchValue"
              [usedLicenses]="usedLicenses || 0"
              [availableLicenses]="availableLicenses || 0"
              (deleteUser)="deleteUserFromProject($event)"
              (deactivateUser)="toggleUserStatus($event)"
              (changeData)="refetch$.next()"
            />
          </div>
        </mat-tab>
        }
      </mat-tab-group>
      } @if (selectedTabIndex === 1) {
      <div class="tab-card-header flexbox align-center smaller-gap">
        <button mat-flat-button color="primary" (click)="openCustomerPortal()" [disabled]="!_permissionService.hasRights('project_read_stripe_info')">
          <mat-icon>diamond</mat-icon>
          Upgrade
        </button>
        <div class="licenses primary">
          <span class="strong">PRO </span>
          <span class="strong">Benutzer </span>
          <span class="bold">{{ usedLicenses }}/{{ availableLicenses }}</span>
        </div>
      </div>
      }
    </mat-card-content>
  </mat-card>
</div>
