<table mat-table [dataSource]="dataSource"
       matSort
       matSortActive="name"
       matSortDirection="asc"
       class="differ-rows">
  <!--  Selection Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? toggleAllRowsSelection() : null"
                    [checked]="this.rowSelection && isAllRowsSelected"
                    [indeterminate]="!!this.rowSelection.selected.length && !isAllRowsSelected"
                    [aria-label]="isAllRowsSelected
                        ? 'Alles abwählen'
                        : 'Alles auswählen'">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? this.rowSelection.toggle(row.id) : null"
                    [checked]="this.rowSelection.isSelected(row.id)"
                    [aria-label]="this.rowSelection.isSelected(row.id)
                  ? 'Abwählen'
                  : 'Auswählen'">
      </mat-checkbox>
    </td>
  </ng-container>
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let row">
      <span [ngClass]="{'disabled-text': row.userStatus === 'inactive'}">{{ row.name }}</span>
    </td>
  </ng-container>
  <!-- Title Column -->
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Position</th>
    <td mat-cell *matCellDef="let row">
      <span [ngClass]="{'disabled-text': row.userStatus === 'inactive'}">{{ row.title }}</span>
    </td>
  </ng-container>
  <!-- Company Column -->
  <ng-container matColumnDef="company">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Firma</th>
    <td mat-cell *matCellDef="let row">
      <span [ngClass]="{'disabled-text': row.userStatus === 'inactive'}">{{ row.company }}</span>
    </td>
  </ng-container>
  <!-- Phone Column -->
  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefon</th>
    <td mat-cell *matCellDef="let row">
      <span [ngClass]="{'disabled-text': row.userStatus === 'inactive'}">{{ row.phone }}</span>
    </td>
  </ng-container>
  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>eMail</th>
    <td mat-cell *matCellDef="let row">
      <span [ngClass]="{'disabled-text': row.userStatus === 'inactive'}">{{ row.email }}</span>
    </td>
  </ng-container>
  <!-- Role Column -->
  <ng-container matColumnDef="userRole">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Benutzerrolle</th>
    <td mat-cell *matCellDef="let row">
      <span [ngClass]="{'disabled-text': row.userStatus === 'inactive'}">
        {{ row.userRole }}
      </span>
    </td>
  </ng-container>
  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Aktionen</th>
    <td mat-cell *matCellDef="let row">
      <!-- only allow users to be deleted if they are not the project Owner -->
      @if (!row.roles.includes('owner')) {
        <section class="action-menu flexbox small-gap">
          <button mat-icon-button [matMenuTriggerFor]="menu" class="open-context-menu">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item aria-label="Delete-Button"
                    [disabled]="!_permissionService.hasRights('project_delete_users', 'project_update_users')"
                    (click)="onDeleteUser(row)">
              <mat-icon class="accent-2">delete</mat-icon>
              <span>Löschen</span>
            </button>
            <button mat-menu-item aria-label="Deactivate-Button"
                    [disabled]="!_permissionService.hasRights('project_update_users')"
                    (click)="onDeactivateUser(row)">
              <mat-icon>
                {{ row.userStatus === 'active' ? 'person_off' : 'person' }}
              </mat-icon>
              <span>
                {{ row.userStatus === 'active' ? 'Deaktivieren' : 'Aktivieren' }}
              </span>
            </button>
          </mat-menu>
        </section>
      }
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4" style="padding-top: .5rem">Es wurden keine Benutzer gefunden</td>
  </tr>
</table>

<mat-paginator
  [pageSizeOptions]="[5, 10, 25, 100]"
  [pageSize]="(_userService.euleUser$ | async)?.pagination"
  (page)="onChangePageSize($event.pageSize)"
  aria-label="Select page of users"
></mat-paginator>

