import { NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { Component, inject, Injector, OnInit } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import {
  catchError,
  filter,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  throwError,
} from 'rxjs';
import { FooterComponent } from '../components/footer/footer.component';
import { NavigationProfileBarComponent } from '../components/navigation-profile-bar/navigation-profile-bar.component';
import { AuthService } from '../services/auth-christian/auth.service';
import { ProjectService } from '../services/project.service';
import { ThemeService } from '../services/theme.service';
import { BaseComponent } from '../components/base/base.component';
import { SnackbarService } from '../services/snackbar.service';
import { SubjectValue } from '@eeule/eeule-shared';
import { mapAndTranslateIndicatorSubjects } from '../../../util/mapping.helper';
import { ScenarioCollectionType } from '../../types/common-types';
import { PermissionService } from '../services/permission.service';

interface FoodNode {
  name: string;
  path?: string;
  nav?: () => void;
  children?: FoodNode[];
}

@Component({
  selector: 'eule-navigation',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatDividerModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    RouterOutlet,
    RouterModule,
    FooterComponent,
    MatTreeModule,
    NavigationProfileBarComponent,
    MatToolbarModule,

  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
})
export class NavigationComponent extends BaseComponent implements OnInit {
  private _injector = inject(Injector);
  public theme$ = toObservable(this._themeService.themeSig, {
    injector: this._injector,
  });

  public PRE_CHECK_TREE_DATA: FoodNode[] = [];

  preCheckTreeControl = new NestedTreeControl<FoodNode>(node => node.children);
  preCheckDataSource = new MatTreeNestedDataSource<FoodNode>();

  public AUDIT_TREE_DATA: FoodNode[] = [];

  auditTreeControl = new NestedTreeControl<FoodNode>(node => node.children);
  auditDataSource = new MatTreeNestedDataSource<FoodNode>();

  public routeChild$: Observable<string | undefined> = new Observable<string | undefined>();

  public routeId$: Observable<string | undefined> = new Observable<string | undefined>();

  constructor(
    public _permissionService: PermissionService,
    public _router: Router,
    public _themeService: ThemeService,
    public _projectService: ProjectService,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _snackbarService: SnackbarService,
  ) {
    super();
  }

  ngOnInit() {
    this.routeChild$ = this.createRouteChildObservable();
    this.routeId$ = this.createRouteIdObservable();

    this.initGlobalePermissions();

    this.generateDataTree('preCheckScenarios');
    this.generateDataTree('auditScenarios');
  }

  initGlobalePermissions() {
    const authUser = this._authService.getAuthUser();
    if (!authUser) {
      this._snackbarService.showErrorMessage('Kein Benutzer gefunden');
      throw new Error('No user found');
    }

  }

  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;

  public navigateInProject(route: string) {
    this.routeId$.pipe(take(1)).subscribe(id => this._router.navigate([`./intern/project/${id}/${route}`]));
  }

  public clickLogout() {
    this._authService.logout().catch(err => {
      this._snackbarService.showErrorMessage('Logout fehlgeschlagen');
      throw new Error(err);
    });
  }

  /**
   * Generates a data tree for the given scenario type.
   *
   * This method retrieves the project and its scenarios, then constructs a data tree
   * based on the scenario type. It updates the tree data source and expands the tree
   * if the current URL matches the scenario type.
   *
   * @param {ScenarioCollectionType} scenarioType - The type of the scenario collection.
   */
  private generateDataTree(scenarioType: ScenarioCollectionType) {
    // Subscribe to the project observable
    this._projectService.project$.pipe(
      // Switch to the observable that retrieves live scenarios for the project
      switchMap(project => {
        // If no project is found, return an observable of null
        if (!project) return of(null);
        // Retrieve live scenarios for the given project ID and scenario type
        return this._projectService.getLiveScenarios(project.id, scenarioType);
      }),
      catchError(err => {
        this._snackbarService.showErrorMessage('Fehler beim Laden der Szenarien');
        return throwError(() => err);
      }),
      takeUntil(this.stop$),
    ).subscribe(scenarios => {
      // If no scenarios are found, exit the function
      if (!scenarios?.length) {
        this.resetDataSourceAndTree(scenarioType);
        return;
      }

      // Determine the sub-route part based on the scenario type
      const scenarioSubRoutePart: string = scenarioType === 'preCheckScenarios' ? 'pre-check' : 'audit-details';

      // Initialize the data tree with a root node
      const dataTree: FoodNode[] = [
        {
          name: scenarioType === 'preCheckScenarios' ? 'Pre-Check' : 'Audit',
          path: `/audit/${scenarioSubRoutePart}/overview`,
          nav: () => this.navigateInProject(`audit/${scenarioSubRoutePart}/overview`),
          children: [],
        },
      ];

      // Get the subject values from the first scenario
      const subjectValues: SubjectValue[] | undefined = scenarios[0].subjectValues;
      // If no subject values are found, exit the function
      if (!subjectValues?.length) {
        this.resetDataSourceAndTree(scenarioType);
        return;
      }

      // Iterate over each subject value and add it to the data tree
      subjectValues.forEach(subjectValue => {
        dataTree[0].children!.push({
          name: mapAndTranslateIndicatorSubjects(subjectValue.subject),
          path: `/audit/${scenarioSubRoutePart}/${subjectValue.subject}`,
          nav: () => this.navigateInProject(`/audit/${scenarioSubRoutePart}/${subjectValue.subject}`),
        });
      });

      // Get the current URL
      const url = this._router.url;

      // Update the data source and expand the tree if the URL matches the scenario type
      if (scenarioType === 'preCheckScenarios') {
        this.PRE_CHECK_TREE_DATA = dataTree;
        this.preCheckDataSource.data = dataTree;
        this.preCheckTreeControl.dataNodes = dataTree;
        if (url.includes('pre-check')) {
          this.preCheckTreeControl.expandAll();
        }
      }
      if (scenarioType === 'auditScenarios') {
        this.AUDIT_TREE_DATA = dataTree;
        this.auditDataSource.data = dataTree;
        this.auditTreeControl.dataNodes = dataTree;
        if (url.includes('audit-details')) {
          this.auditTreeControl.expandAll();
        }
      }
    });
  }

  /**
   * Resets the data source and tree control for the specified scenario type.
   *
   * This method clears the data tree and updates the data source and tree control
   * for the given scenario type. It supports two types of scenarios: 'preCheckScenarios'
   * and 'auditScenarios'.
   *
   * @param {ScenarioCollectionType} type - The type of the scenario collection.
   */
  resetDataSourceAndTree(type: ScenarioCollectionType) {
    if (type === 'preCheckScenarios') {
      this.PRE_CHECK_TREE_DATA = [];
      this.preCheckDataSource.data = this.PRE_CHECK_TREE_DATA;
      this.preCheckTreeControl.dataNodes = this.PRE_CHECK_TREE_DATA;
    }
    if (type === 'auditScenarios') {
      this.AUDIT_TREE_DATA = [];
      this.auditDataSource.data = this.AUDIT_TREE_DATA;
      this.auditTreeControl.dataNodes = this.AUDIT_TREE_DATA;
    }
  }

  /**
   * Creates an observable that emits the path of the current route's child.
   *
   * This method sets up an observable that:
   * 1. Emits the initial path of the first child route.
   * 2. Listens for navigation events and emits the path of the first child route when the navigation ends.
   *
   * @private
   * @returns {Observable<string | undefined>} An observable that emits the path of the current route's child.
   */
  private createRouteChildObservable(): Observable<string | undefined> {
    const initialPath$ = of(this._route.snapshot.firstChild?.firstChild?.routeConfig?.path);

    const routeChangePath$ = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this._route.firstChild?.snapshot.firstChild?.routeConfig?.path),
    );

    return merge(initialPath$, routeChangePath$);
  }

  /**
   * Creates an observable that emits the ID parameter of the current route.
   *
   * This method sets up an observable that:
   * 1. Emits the initial ID parameter of the first child route.
   * 2. Listens for navigation events and emits the ID parameter of the first child route when the navigation ends.
   *
   * @private
   * @returns {Observable<string | undefined>} An observable that emits the ID parameter of the current route.
   */
  private createRouteIdObservable(): Observable<string | undefined> {
    const initialId$ = of(this._route.snapshot.firstChild?.params['id']);

    const routeChangeId$ = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this._route.firstChild?.snapshot.params['id']),
    );

    return merge(initialId$, routeChangeId$).pipe(shareReplay(1));
  }
}
