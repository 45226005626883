import { Component } from '@angular/core';

@Component({
  selector: 'eule-no-permissions',
  standalone: true,
  imports: [],
  templateUrl: './no-permissions.component.html',
  styleUrl: './no-permissions.component.scss'
})
export class NoPermissionsComponent {

}
