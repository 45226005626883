/**
 * Translation helper for chart labels.
 */
export const chartLabels: { [key: string]: string } = {
  DONE: 'Fertig',
  OPEN: 'Offen',
  IN_PROGRESS: 'In Bearbeitung',
  INACTIVE: 'Inaktiv',
  CRITICAL: 'Kritisch',
  OPTIONAL: 'Optional',
  fulfillment: 'Erfüllungsgrad',
  costs: 'Kosten',
  euroPerFulfillment: '€ pro % Erfüllungsgrad',
  euroPerClp: '€ pro Clp',
  euroPerHu: '€ pro Wohneinheit',
  clpPerFulfillment: 'Clp pro % Erfüllungsgrad',
}

export const userRoles: { [key: string]: string } = {
  'superadmin': 'Superadmin',
  'admin': 'Admin',
  'owner': 'Eigentümer',
  'auditor': 'Auditor',
  'member': 'Mitglied',
  'viewer': 'Betrachter',
}

export const userStatus: { [key: string]: string } = {
  'active': 'Aktiv',
  'inactive': 'Inaktiv',
  'pending': 'Einladung ausstehend',
}
