import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { from, lastValueFrom, Observable, switchMap } from 'rxjs';
import { CollectionQueryResponse, DocumentQueryResponse } from '../../types/firebase-types';
import { Role, RoleAssignment, RoleId } from '@eeule/eeule-shared';
import { deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class RoleService {

  constructor(
    private _firebaseService: FirebaseService,
  ) {
  }

  /**
   * Retrieves all roles.
   *
   * @returns {Observable<CollectionQueryResponse<Role>>} An observable emitting an array of roles.
   */
  public getRoles(): Observable<CollectionQueryResponse<Role>> {
    return this._firebaseService.getCollectionData<Role>('roles', undefined, undefined, true);
  }

  /**
   * Retrieves a role by its ID.
   *
   * @param {RoleId} roleId - The ID of the role to retrieve.
   * @returns {Observable<DocumentQueryResponse<Role>>} An observable emitting the role data.
   */
  public getRoleById(roleId: RoleId): Observable<DocumentQueryResponse<Role>> {
    return this._firebaseService.getDocumentData<Role>('roles', roleId, true);
  }

  /**
   * Retrieves all role assignments.
   *
   * @returns {Observable<CollectionQueryResponse<RoleAssignment>>} An observable emitting an array of role assignments.
   */
  public getRoleAssignments(): Observable<CollectionQueryResponse<RoleAssignment>> {
    return this._firebaseService.getCollectionData<RoleAssignment>('roleAssignments', undefined, undefined, true);
  }

  /**
   * Retrieves a role assignment by user ID.
   *
   * @param {string} userId - The ID of the user to retrieve the role assignment for.
   * @returns {Observable<DocumentQueryResponse<RoleAssignment>>} An observable emitting the role assignment data.
   */
  public getRoleAssignmentByUserId(userId: string): Observable<DocumentQueryResponse<RoleAssignment>> {
    return this._firebaseService.getDocumentData<RoleAssignment>('roleAssignments', userId, true);
  }

  /**
   * Sets a role assignment for a user.
   *
   * @param {string} userId - The ID of the user.
   * @param {Partial<RoleAssignment>} data - The data to set in the role assignment.
   * @returns {Observable<void>} An observable that completes when the role assignment is set.
   */
  public setRoleAssignment(userId: string, data: Partial<RoleAssignment>): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `roleAssignments`, userId);
    return from(setDoc(docRef, data));
  }

  /**
   * Updates a role assignment for a user.
   *
   * @param {string} userId - The ID of the user.
   * @param {Partial<RoleAssignment>} data - The data to update in the role assignment.
   * @returns {Observable<void>} An observable that completes when the role assignment is updated.
   */
  public updateRoleAssignment(userId: string, data: Partial<RoleAssignment>): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `roleAssignments`, userId);
    return from(updateDoc(docRef, data));
  }

  /**
   * Retrieves all role assignments for a specific project.
   *
   * @param {string} projectId - The ID of the project to retrieve role assignments for.
   * @returns {Observable<CollectionQueryResponse<RoleAssignment>>} An observable emitting an array of role assignments for the project.
   */
  public getProjectRoleAssignments(projectId: string): Observable<CollectionQueryResponse<RoleAssignment>> {
    return this._firebaseService.getCollectionData<RoleAssignment>(`projects/${projectId}/roleAssignments`, undefined, undefined, true);
  }

  /**
   * Retrieves a role assignment for a specific project and user.
   *
   * @param {string} projectId - The ID of the project.
   * @param {string} userId - The ID of the user.
   * @returns {Observable<DocumentQueryResponse<RoleAssignment>>} An observable emitting the role assignment data for the project and user.
   */
  public getProjectRoleAssignmentByUserId(projectId: string, userId: string): Observable<DocumentQueryResponse<RoleAssignment>> {
    return this._firebaseService.getDocumentData<RoleAssignment>(`projects/${projectId}/roleAssignments`, userId, true);
  }

  /**
   * Retrieves a live role assignment for a specific project and user.
   *
   * @param {string} projectId - The ID of the project.
   * @param {string} userId - The ID of the user.
   * @returns {Observable<DocumentQueryResponse<RoleAssignment>>} An observable emitting the role assignment data for the project and user.
   */
  public getLiveProjectRoleAssignmentByUserId(projectId: string, userId: string): Observable<RoleAssignment> {
    return this._firebaseService.listenToDocumentChangesOnSnapshot<RoleAssignment>(`projects/${projectId}/roleAssignments`, userId);
  }

  /**
   * Adds a role assignment for a specific project and user and sets its id to the auth user id.
   *
   * @param {string} projectId - The ID of the project.
   * @param {string} userId - The ID of the user.
   * @param {Partial<RoleAssignment>} data - The data to update in the role assignment.
   * @returns {Observable<void>} An observable that completes when the update is done.
   */
  public setProjectRoleAssignment(projectId: string, userId: string, data: RoleAssignment): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/roleAssignments`, userId);
    return from(setDoc(docRef, data));
  }

  /**
   * Updates a role assignment for a specific project and user.
   *
   * @param {string} projectId - The ID of the project.
   * @param {string} userId - The ID of the user.
   * @param {Partial<RoleAssignment>} data - The data to update in the role assignment.
   * @returns {Observable<void>} An observable that completes when the update is done.
   */
  public updateProjectRoleAssignment(projectId: string, userId: string, data: Partial<RoleAssignment>): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/roleAssignments`, userId);
    return from(updateDoc(docRef, data));
  }

  /**
   * Adds a role to a user's role assignment for a specific project.
   *
   * @param {string} projectId - The ID of the project.
   * @param {string} userId - The ID of the user.
   * @param {RoleId} roleId - The ID of the role to add.
   */
  public addProjectRoleAssignmentUserRole(projectId: string, userId: string, roleId: RoleId) {
    this.getProjectRoleAssignmentByUserId(projectId, userId).pipe(
      switchMap((roleAssignment: DocumentQueryResponse<RoleAssignment>) => {
        const currentRoles = roleAssignment?.data?.roles.map(role => role.id).filter(o => {
          return o !== roleId;
        }) || [];
        currentRoles.push(roleId);
        return this.updateProjectRoleAssignment(projectId, userId, {
          roles: currentRoles.map(role => {
            return doc(this._firebaseService.firestore, 'roles', role);
          }),
        });
      }),
    );
  }

  /**
   * Changes a role to a user's role assignment for a specific project.
   * if the new role is member, viewer or auditor, it will remove all corresponding roles,
   * so that the user is either member, viewer or auditor.
   *
   * @param {string} projectId - The ID of the project.
   * @param {string} userId - The ID of the user.
   * @param {RoleId} roleId - The ID of the role to add.
   */
  public async changeProjectRoleAssignmentRoles(projectId: string, userId: string, roleId: RoleId) {
    const currentRoleAssignment
      = await lastValueFrom(this.getProjectRoleAssignmentByUserId(projectId, userId));

    if (!currentRoleAssignment.data) {
      const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/roleAssignments`, userId);
      await setDoc(docRef, {
        id: userId,
        roles: [doc(this._firebaseService.firestore, 'roles', roleId)],
      })
    }

    await lastValueFrom(this.getProjectRoleAssignmentByUserId(projectId, userId).pipe(
      switchMap((roleAssignment: DocumentQueryResponse<RoleAssignment>) => {
        let currentRoles = roleAssignment?.data?.roles.map(role => role.id).filter(o => {
          return o !== roleId;
        }) || [];
        if (roleId === 'viewer' || roleId === 'member' || roleId === 'auditor') {
          currentRoles = [...currentRoles.filter(o => o !== 'viewer' && o !== 'member' && o !== 'auditor')];
        }
        currentRoles.push(roleId);
        return this.updateProjectRoleAssignment(projectId, userId, {
          roles: currentRoles.map(role => {
            return doc(this._firebaseService.firestore, 'roles', role);
          }),
        });
      }),
    ));
  }

  /**
   * Removes a role assignment for a specific project and user.
   *
   * @param {string} projectId - The ID of the project.
   * @param {string} userId - The ID of the user.
   * @returns {Observable<void>} An observable that completes when the role assignment is removed.
   */
  public removeProjectRoleAssignmentUserRole(projectId: string, userId: string) {
    return from(deleteDoc(doc(this._firebaseService.firestore, `projects/${projectId}/roleAssignments`, userId)));
  }
}
