import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { PermissionService } from '../../services/permission.service';
import { inject } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { map, Observable, of, switchMap, tap, combineLatest } from 'rxjs';
import { Auth } from '@angular/fire/auth';

/**
 * Guard that checks if the current user has the necessary global and/or project permissions to activate a route.
 *
 * @param {ActivatedRouteSnapshot} route - The activated route snapshot.
 * @returns {Observable<boolean>} An observable emitting true if the user has the necessary permissions, false otherwise.
 */
export const permissionGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const _router = inject(Router);
  const globalRightsNeededToActivate: string[] = route.data['globalRights'] || [];
  const projectRightsNeededToActivate: string[] = route.data['projectRights'] || [];
  const needAllRights: boolean = route.data['needAllRights'] || false;

  if (!globalRightsNeededToActivate.length && !projectRightsNeededToActivate.length) {
    return of(true);
  }

  const hasProjectPermissions$: Observable<boolean> = projectRightsNeededToActivate.length
    ? checkForProjectPermissions(route.params['id'], projectRightsNeededToActivate, needAllRights)
    : of(true);

  const hasGlobalPermissions$: Observable<boolean> = globalRightsNeededToActivate.length
    ? checkForGlobalPermissions(globalRightsNeededToActivate, needAllRights)
    : of(true);

  return combineLatest([hasProjectPermissions$, hasGlobalPermissions$]).pipe(
    map(([hasProjectPermissions, hasGlobalPermissions]) => hasProjectPermissions && hasGlobalPermissions),
    tap(hasPermission => {
      if (!hasPermission) {
        _router.navigate(['/intern/no-permission']).catch(error => {
          console.error('An error occurred while navigating to the no-permission route:', error);
        });
      }
    }),
  );
};

/**
 * Checks if the current user has the necessary project permissions to activate a route.
 *
 * @param {string} projectId - The ID of the project.
 * @param {string[]} projectRightsNeededToActivate - An array of project rights needed to activate the route.
 * @param needAllRights - A boolean indicating if the user needs all the global rights to activate the route.
 * @returns {Observable<boolean>} An observable emitting true if the user has the necessary project permissions, false otherwise.
 */
const checkForProjectPermissions = (
  projectId: string,
  projectRightsNeededToActivate: string[],
  needAllRights: boolean = false,
): Observable<boolean> => {
  const _permissionService = inject(PermissionService);
  const _projectService = inject(ProjectService);

  const userProjectPermissions: string[] | null = _permissionService.currentUserProjectRightsSig();

  if (userProjectPermissions) {
    return needAllRights
      ? of(projectRightsNeededToActivate.every(right => userProjectPermissions.includes(right)))
      : of(projectRightsNeededToActivate.some(right => userProjectPermissions.includes(right)));
  }

  return _projectService.getCurrentProjectUser(projectId).pipe(
    switchMap(projectUser => {
      if (!projectUser.authUserId) {
        return of(false);
      }
      return _permissionService.getUserProjectPermissions(projectUser.authUserId, projectId).pipe(
        tap(permissions => {
          const userProjectRights = permissions?.data?.rights || [];
          _permissionService.setCurrentUserProjectRights(userProjectRights);
        }),
        map(permissions => {
          const userProjectRights = permissions?.data?.rights || [];
          return needAllRights
            ? projectRightsNeededToActivate.every(right => userProjectRights.includes(right))
            : projectRightsNeededToActivate.some(right => userProjectRights.includes(right));
        }),
      );
    }),
  );
};

/**
 * Checks if the current user has the necessary global permissions to activate a route.
 *
 * @param {string[]} globalRightsNeededToActivate - An array of global rights needed to activate the route.
 * @param needAllRights - A boolean indicating if the user needs all the global rights to activate the route.
 * @returns {Observable<boolean>} An observable emitting true if the user has the necessary global permissions, false otherwise.
 */
const checkForGlobalPermissions = (
  globalRightsNeededToActivate: string[],
  needAllRights: boolean = true,
): Observable<boolean> => {
  const _permissionService: PermissionService = inject(PermissionService);
  const _auth: Auth = inject(Auth);

  const currentUserId: string | undefined = _auth.currentUser?.uid;

  if (!currentUserId) {
    return of(false);
  }

  const userGlobalPermissions: string[] | null = _permissionService.currentUserRightsSig();

  if (userGlobalPermissions) {
    return needAllRights
      ? of(globalRightsNeededToActivate.every(right => userGlobalPermissions.includes(right)))
      : of(globalRightsNeededToActivate.some(right => userGlobalPermissions.includes(right)));
  }

  return _permissionService.getUserPermissions(currentUserId).pipe(
    tap(permissions => {
      const globalUserRights = permissions?.data?.rights || [];
      _permissionService.setCurrentUserRights(globalUserRights);
    }),
    map(permissions => {
      const globalUserRights = permissions?.data?.rights || [];
      return needAllRights
        ? globalRightsNeededToActivate.every(right => globalUserRights.includes(right))
        : globalRightsNeededToActivate.some(right => globalUserRights.includes(right));
    }),
  );
};
