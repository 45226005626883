export const environment = {
  production: false,
  stage: 'develop',
  companyId: 'krc4PsvgVuBKYck4mxen',
  amChartsKey: 'AM5C-5078-8816-8831-9926',
  firebaseConfig: {
    apiKey: 'AIzaSyAyPH0qZrj8ImH_znCnkJ8FnkFKMZht5V4',
    authDomain: 'eule-dev.firebaseapp.com',
    projectId: 'eule-dev',
    storageBucket: 'eule-dev.appspot.com',
    messagingSenderId: '461739700843',
    appId: '1:461739700843:web:5f2bbfffe311c12869f72e',
    measurementId: 'G-2MBZH9X3YM',
  },
  stripeConfig: {
    publishableKey: 'pk_test_51PyUveIwUI6a3OOJ30RMHVnavmDLyHNz803bhLYb8ObxRsHmxwzgeENivC0ZCRul8YmmTfimjNlkAeOtRtX048fC006bAgEMPp',
  },
};
