import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EuleAutofocusDirective } from '../../../core/directives/eule-autofocus.component';

interface InviteUserForm {
  invitedUserEmail: FormControl<string | null>;
}

export interface AddUserToProjectDialogComponentConfig {}
export interface AddUserToProjectDialogResult {
  invitedUserEmail: string;
}

@Component({
  selector: 'eule-add-user-to-project-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatDialogModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, EuleAutofocusDirective],
  templateUrl: './add-user-to-project-dialog.component.html',
  styleUrl: './add-user-to-project-dialog.component.scss',
})
export class AddUserToProjectDialogComponent {
  public dataForm: FormGroup<InviteUserForm> = this._formBuilder.group({
    invitedUserEmail: this._formBuilder.control('', [Validators.email, Validators.required]),
  });

  public get invitedUserEmailControl() {
    return this._convertToFormControl(this.dataForm.get('invitedUserEmail'));
  }

  public constructor(
    private _dialogRef: MatDialogRef<AddUserToProjectDialogComponent, AddUserToProjectDialogResult | null>,
    private _formBuilder: FormBuilder
  ) {}

  public closeDialog(save: boolean) {
    if (!this.dataForm.value) {
      throw new Error('No Email provided');
    }
    if (save) {
      this._dialogRef.close(this.dataForm.value as AddUserToProjectDialogResult);
    } else {
      this._dialogRef.close(null);
    }
  }

  private _convertToFormControl(absCtrl: AbstractControl | null): FormControl {
    return absCtrl as FormControl;
  }
}
