import { AfterViewInit, Component, Input } from '@angular/core';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { ProjectService } from '../../../../core/services/project.service';
import { AsyncPipe } from '@angular/common';
import {
  CompareValues,
  CompareValuesVisualizationComponent,
} from '../../../../core/components/compare-values-visualization/compare-values-visualization.component';
import { DocumentService } from '../../../../core/services/document.service';
import { FileSizeAndUnit, retrieveAndFormatFileSize } from '../../../../../util/conversion.helper';
import { retrieveErrorMessageFromUnknownError } from '../../../../../util/error.helper';
import { RoleService } from '../../../../core/services/role.service';
import { SnackbarService } from '../../../../core/services/snackbar.service';

@Component({
  selector: 'eule-audit-summary',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardTitle,
    MatProgressSpinner,
    AsyncPipe,
    CompareValuesVisualizationComponent,
  ],
  templateUrl: './audit-summary.component.html',
  styleUrl: './audit-summary.component.scss',
})
export class AuditSummaryComponent implements AfterViewInit {
  // The task data
  @Input({ required: true }) tasksCountVsDone: CompareValues | null = null;

  // scenario loading indicator
  scenariosLoading: boolean = true;

  // document loading indicator
  documentsLoading: boolean = true;

  // user loading indicator
  usersLoading: boolean = true;

  // The comparison of the number of pre-check scenarios versus audit scenarios
  // Non-null assertion, we are expecting to get the initializer value in ngAfterViewInit
  preCheckVsAuditScenarios$!: Observable<CompareValues | null>;

  // The comparison of the number of files versus the total size of files
  fileCountVsSize$!: Observable<CompareValues | null>;

  // The comparison of the number of users versus the number of viewers
  userCountVsViewerCount$!: Observable<CompareValues | null>;

  constructor(
    private _projectService: ProjectService,
    private _documentService: DocumentService,
    private _roleService: RoleService,
    private _snackbarService: SnackbarService
  ) {
  }

  ngAfterViewInit() {
    this.preCheckVsAuditScenarios$ = this.initPreCheckVsAuditScenarios$;
    this.fileCountVsSize$ = this.initFileCountVsSize$;
    this.userCountVsViewerCount$ = this.initUserCountVsViewerCount$;
  }

  /**
   * Initializes an observable that emits the comparison of the number of pre-check scenarios
   * versus audit scenarios for the current project.
   *
   * @private
   * @returns {Observable<CompareValues | null>} An observable emitting CompareValues or null.
   */
  private get initPreCheckVsAuditScenarios$(): Observable<CompareValues | null> {
    return this._projectService.project$.pipe(
      distinctUntilChanged(), // Avoid duplicate emissions from project$
      switchMap(project => {
        if (!project) {
          return of(null);
        }
        return combineLatest([
          this._projectService.getScenarios(project.id, 'preCheckScenarios'),
          this._projectService.getScenarios(project.id, 'auditScenarios'),
        ]).pipe(
          map(([preCheckScenarios, auditScenarios]) => {
            const preCheckVsAuditScenarios: CompareValues = {
              leftValue: {
                value: preCheckScenarios?.data?.length || 0,
                subText: 'Pre Check',
              },
              rightValue: {
                value: auditScenarios?.data?.length || 0,
                subText: 'Audit',
              },
            };
            return preCheckVsAuditScenarios;
          }),
        );
      }),
      tap(() => {
        this.scenariosLoading = false;
      }),
      shareReplay(1), // Cache the result to prevent redundant calculations
    );
  }

  /**
   * Initializes an observable that emits the comparison of the number of files
   * versus the total size of files for the current project.
   *
   * @private
   * @returns {Observable<CompareValues | null>} An observable emitting CompareValues or null.
   */
  private get initFileCountVsSize$(): Observable<CompareValues | null> {
    return this._projectService.project$.pipe(
      distinctUntilChanged(), // Avoid duplicate emissions from project$
      switchMap(project => {
        if (!project) {
          return of(null);
        }
        return this._documentService.getAllProjectDocumentsWithMetaData(project.id);
      }),
      map(documents => {
        if (!documents) {
          return null;
        }
        const filesSizeInBytes: number = documents.map(doc => doc.metadata?.size || 0).reduce((acc, size) => acc + size, 0);
        const filesSizeAndUnit: FileSizeAndUnit = retrieveAndFormatFileSize(filesSizeInBytes);
        const fileCountVsSize: CompareValues = {
          leftValue: {
            value: documents?.length || 0,
            subText: 'Dateien',
          },
          rightValue: {
            value: filesSizeAndUnit.value,
            subText: filesSizeAndUnit.unit + " verwendet",
          },
        };
        return fileCountVsSize;
      }),
      tap(() => {
        this.documentsLoading = false;
      }),
      shareReplay(1), // Cache the result to prevent redundant calculations
    );
  }

  /**
   * Initializes an observable that emits the comparison of the number of users
   * versus the number of viewers for the current project.
   *
   * @private
   * @returns {Observable<CompareValues | null>} An observable emitting CompareValues or null.
   */
  private get initUserCountVsViewerCount$(): Observable<CompareValues | null> {
    return this._projectService.project$.pipe(
      distinctUntilChanged(), // Avoid duplicate emissions from project$
      switchMap(project => {
        if (!project) {
          return of(null);
        }
        return this._projectService.getProjectUsersWithRolesAndRoleAssignments(project.id)
      }),
      map(users => {
        if (!users) {
          return null;
        }
        const userCountVsViewerCount: CompareValues = {
          leftValue: {
            value: users.length || 0,
            subText: 'Benutzer',
          },
          rightValue: {
            value: users.filter(user => !!user.roles?.includes('viewer')).length || 0,
            subText: 'Betrachter',
          },
        };
        return userCountVsViewerCount;
      }),
      tap(() => {
        this.usersLoading = false;
      }),
      catchError((error) => {
        this._snackbarService.showErrorMessage('Fehler beim Laden der Projektbenutzer.');
        const _error = retrieveErrorMessageFromUnknownError(error);
        console.error(_error)
        return of(null);
      }),
      shareReplay(1), // Cache the result to prevent redundant calculations
    );
  }

}
