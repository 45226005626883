<!-- UsageProfile Parameters -->
<ng-container [formGroup]="usageProfileFormGroup">
  <div class="row">
    <div class="col">
      <h2 class="bordered default-border-color">
        @switch (getUsageProfileType) { @case ('mainUsage') { Hauptnutzung } @case ('subordinateUsage') { Untergeordnete Nutzung } @case ('sideUsage')
        {Nebennutzung } }
      </h2>
      @for (profileType of projectService.usageProfiles$ | async; track profileType) { @if (profileType.id === getUsageProfileId) {
      {{ usageProfileEnum[profileType.name] }}

      <div class="row flexbox align-center xs-gap">
        <h4>Flächennutzung nach DIN277</h4>
        <mat-icon fontIcon="info"
                  fixedWidth="300px"
                  [tooltipPositionOffsetY]="4"
                  [htmlTooltipContent]="true"
                  [euleTooltip]="tooltips['flaechenberechnungNachDin277']"></mat-icon>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>BGF (R)</mat-label>
          <input matInput formControlName="bgf" data-cy="input-usageProfileParameters-bgf" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>BRI</mat-label>
          <input matInput formControlName="bri" data-cy="input-usageProfileParameters-bri" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>NRF (R)</mat-label>
          <input matInput formControlName="nrf" data-cy="input-usageProfileParameters-nrf" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>NUF (R)</mat-label>
          <input matInput formControlName="nuf" data-cy="input-usageProfileParameters-nuf" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>TF (R)</mat-label>
          <input matInput formControlName="tf" data-cy="input-usageProfileParameters-tf" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>VF (R)</mat-label>
          <input matInput formControlName="vf" data-cy="input-usageProfileParameters-vf" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>KGF (R)</mat-label>
          <input matInput formControlName="kgf" data-cy="input-usageProfileParameters-kgf" />
        </mat-form-field>
      </div>
      <div class="row flexbox align-center xs-gap">
        <h3>DGNB-Bemessungsfläche</h3>
        <mat-icon fontIcon="info"
                  fixedWidth="400px"
                  [tooltipPositionOffsetY]="4"
                  [htmlTooltipContent]="true"
                  [euleTooltip]="tooltips['bemessungsflaeche']"></mat-icon>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>ADGNB (R)</mat-label>
          <input matInput formControlName="adgnb" data-cy="input-usageProfileParameters-adgnb" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>NF Fahrzeug</mat-label>
          <input matInput formControlName="nfVehicle" data-cy="input-usageProfileParameters-nfVehicle" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>VF Hallen,Flure</mat-label>
          <input matInput formControlName="vfHallCorridor" data-cy="input-usageProfileParameters-vfHallCorridor" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>Anteil in %</mat-label>
          <input matInput formControlName="percentage" data-cy="input-usageProfileParameters-percentage" />
        </mat-form-field>
      </div>
      } }
    </div>
    <div class="col">
      <h2 class="bordered default-border-color">Geschosse</h2>

      @for(floor of floors.controls; track floor){

      <div class="row">
        <button
          mat-raised-button
          color="primary"
          (click)="openFloor($index)"
          class="btn vertical no-shadow open-floor-button"
          [attr.data-cy]="'button-usageProfileParameters-openFloor' + $index"
        >
          {{ floor.get('name')?.value }}
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-raised-button
          color="warn"
          (click)="deleteFloor($index)"
          class="btn vertical no-shadow delete-floor-button"
          [attr.data-cy]="'button-usageProfileParameters-deleteFloor' + $index"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      }
      <button mat-raised-button color="primary" (click)="openFloor(null)" class="btn vertical no-shadow" data-cy="button-usageProfileParameters-addFloor">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
