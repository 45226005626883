import { Injectable } from '@angular/core';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { fromEvent, merge } from 'rxjs';
import { auditTime, debounceTime, tap } from 'rxjs/operators';
import { FirebaseService } from '../firebase.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityTrackerService {
  private userId: string | null = null;

  constructor(private _firebaseService: FirebaseService) {}

  init(userId: string) {
    this.userId = userId;

    // Überwache Benutzerinteraktionen
    const activityEvents$ = merge(fromEvent(document, 'mousemove'), fromEvent(document, 'click'), fromEvent(document, 'keydown'));

    // Update Firestore bei Aktivität
    activityEvents$.pipe(auditTime(3000)).subscribe(() => this.updateLastActive());
  }

  private async updateLastActive() {
    if (!this.userId) return;

    const userDoc = doc(this._firebaseService.firestore, `users/${this.userId}`);
    await updateDoc(userDoc, { lastActive: serverTimestamp() });
  }
}
