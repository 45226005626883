<eule-search-bar id="search-field" [(search)]="filterValue" (searchChange)="applyFilter($event)"> </eule-search-bar>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Titel</th>
      <td mat-cell *matCellDef="let row">{{ row.title }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>
    <ng-container matColumnDef="responsible">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Verantwortlicher</th>
      <td mat-cell *matCellDef="let row">{{ row.responsible }}</td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
      <td mat-cell *matCellDef="let row">{{ row.date }}</td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Standort</th>
      <td mat-cell *matCellDef="let row">{{ row.location }}</td>
    </ng-container>
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Kategorie</th>
      <td mat-cell *matCellDef="let row">{{ row.category }}</td>
    </ng-container>
    <ng-container matColumnDef="preCheck">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pre-Check</th>
      <td mat-cell *matCellDef="let row">{{ row.preCheck }}</td>
    </ng-container>
    <ng-container matColumnDef="preCertification">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Vorzertifizierung</th>
      <td mat-cell *matCellDef="let row">{{ row.preCertification }}%</td>
    </ng-container>
    <ng-container matColumnDef="certification">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Zertifizierung</th>
      <td mat-cell *matCellDef="let row">{{ row.certification }}%</td>
    </ng-container>
    <ng-container matColumnDef="QNG">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>QNG</th>
      <td mat-cell *matCellDef="let row">{{ row.QNG }}%</td>
    </ng-container>
    <ng-container matColumnDef="euTaxonomy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>EU-Taxonomie</th>
      <td mat-cell *matCellDef="let row">{{ row.euTaxonomy }}%</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="changeProjectPlan(row)"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell no-data" colspan="4">Keine Daten vorhanden</td>
    </tr>
  </table>

  <mat-paginator
    #_paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageSize]="(userService.euleUser$ | async)?.pagination"
    (page)="onChangePageSize($event.pageSize)"
    aria-label="Select page of users"
  ></mat-paginator>
</div>
