@for (certificationDescriptionFormGroup of certificationDescriptionFormArray.controls; track certificationDescriptionFormGroup) {
<eule-certification-description-group [certificationDescriptionFormGroup]="certificationDescriptionFormGroup">
  @switch ($index) { @case (0) { @if($index === 0){<ng-container *ngTemplateOutlet="generalDataTemplate"></ng-container>
  } } @case (1) {
  <ng-container *ngTemplateOutlet="systemDataTemplate"></ng-container>
  } @case (2) {
  <ng-container *ngTemplateOutlet="euTaxonomyDataTemplate"></ng-container>
  } @case (3) {
  <ng-container *ngTemplateOutlet="qngDataTemplate"></ng-container>
  }}
</eule-certification-description-group>
<mat-divider></mat-divider>
}

<ng-template #generalDataTemplate [formGroup]="certificationFormGroup">
  <div class="col">
    <div class="row">
      <mat-form-field class="w-100-percent smaller-font">
        <mat-label>Bauphase</mat-label>
        <mat-select formControlName="lifeCyclePhase" data-cy="select-tab-certification-lifeCyclePhase">
          @for (phase of lifeCyclePhaseEnum | keyvalue; track phase) {
          <mat-option [value]="phase.key">{{ phase.value }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="w-100-percent smaller-font">
        <mat-label>Zertifizierungssystem</mat-label>
        <input matInput [formControl]="displayOnlyFormControl" data-cy="input-tab-certification-displayOnlyFormControl" />
      </mat-form-field>
    </div>
    <div class="row spacer">
      <mat-slide-toggle formControlName="bgfBigger5000" data-cy="toggle-tab-certification-bgfBigger5000"></mat-slide-toggle>
      <mat-label>Bruttogeschossfläche > 5.000 m2</mat-label>
    </div>
    <div class="row spacer">
      <mat-slide-toggle formControlName="withDeconstruction" data-cy="toggle-tab-certification-withDeconstruction"></mat-slide-toggle>
      <mat-label>Mit Rückbau</mat-label>
    </div>
    <div class="row spacer">
      <mat-slide-toggle formControlName="hasMixedUsageProfiles" #mixedUsageProfiles data-cy="toggle-tab-certification-hasMixedUsageProfiles"></mat-slide-toggle>
      <mat-label>Mischnutzung</mat-label>
      <mat-icon fontIcon="info"
                fixedWidth="500px"
                [euleTooltip]="tooltips['hasMixedUsageProfiles']"
                [htmlTooltipContent]="true"
                [tooltipPositionOffsetY]="4"
      ></mat-icon>
<!--      <mat-icon fontIcon="info" [matTooltip]="tooltips['hasMixedUsageProfiles']"></mat-icon>-->
    </div>
    <div class="row spacer">
      <mat-form-field class="w-100-percent smaller-font">
        <mat-label>Zertifikatsstufe der aktuellen Phase des Projektes</mat-label>
        <mat-select formControlName="selectedCertificationLevel" data-cy="select-tab-certification-selectedCertificationLevel">
          <mat-option *ngFor="let zertifikat of projectCertificationLevels$ | async" [value]="zertifikat.id">{{ zertifikat.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template #systemDataTemplate [formGroup]="certificationFormGroup">
  <mat-form-field class="w-100-percent smaller-font">
    <mat-label>Welches Zertifizierungsniveau wird angestrebt?</mat-label>
    <mat-select
      formControlName="selectedErfuellungsGradSystem"
      [compareWith]="compareErfuellungsGrade"
      data-cy="select-tab-certification-selectedErfuellungsGradSystem"
    >
      <mat-option *ngFor="let grad of systemErfuellungsGrade$ | async" [value]="grad">{{ grad.name }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
<ng-template #euTaxonomyDataTemplate [formGroup]="certificationFormGroup">
  <mat-form-field class="w-100-percent smaller-font">
    <mat-label>ESG-Verifikation</mat-label>
    <mat-select formControlName="selectedErfuellungsGradEuTaxonomy" data-cy="select-tab-certification-selectedErfuellungsGradEuTaxonomy">
      <mat-option *ngFor="let grad of euTaxonomyErfuellungsGrade$ | async" [value]="grad.id">{{ grad.value }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
<ng-template #qngDataTemplate [formGroup]="certificationFormGroup">
  <mat-form-field class="w-100-percent smaller-font">
    <mat-label>QNG</mat-label>
    <mat-select formControlName="selectedErfuellungsGradQng" data-cy="select-tab-certification-selectedErfuellungsGradQng">
      <!-- <mat-option [value]="null">Nein</mat-option> -->
      <mat-option *ngFor="let grad of qngErfuellungsGrade$ | async" [value]="grad.id">{{ grad.value }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
