<div class="audit-documents">
  <eule-general-title [title]="'Dokumente'">
    <div class="flexbox smaller-gap align-center">
      <div [euleTooltip]="_permissionService.hasRights('project_create_document')
              ? null
              : 'Sie besitzen nicht die benötigten Rechte, um ein Projekt-Dokument zu erstellen.'"
           [noWrap]="true"
           [tooltipPositionOffsetY]="6">
        <button
          mat-flat-button
          color="primary"
          class="btn vertical"
          [disabled]="!_permissionService.hasRights('project_create_document')"
          (click)="newDocument()">
          <mat-icon>add</mat-icon>
          Dokument
        </button>
      </div>
      <div class="v-divider"></div>

      <eule-search-bar id="search-field" [(search)]="filterValue"
                       (searchChange)="applyFilter($event)"></eule-search-bar>
      <!--  <button mat-mini-fab [disabled]="true">-->
      <!--    <mat-icon>filter_list</mat-icon>-->
      <!--  </button>-->
    </div>
  </eule-general-title>

  <mat-card class="custom-card">
    <mat-card-content class="small-padding documents-table-card-content custom-scrollbar">
      @if (isLoading$ | async) {
        <div class="loading-overlay covering h-100-percent card-background">
          <mat-spinner diameter="50" />
        </div>
      }
      <table mat-table [dataSource]="dataSource" matSort class="differ-rows">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let row">
            @if (canOpenDocument(row)) {
              <a class="primary cursor-pointer" (keydown)="onAttachmentKeyDown($event, row.id)" tabindex="0"
                 role="button"
                 (click)="onOpenAttachment(row.id)">
                {{ row.name?.length > 16 ? (row.name | slice : 0 : 16) + '...' : row.name }}
              </a>
            } @else {
              <span class="secondary-text">{{ row.name?.length > 16 ? (row.name | slice : 0 : 16) + '...' : row.name }}</span>
            }
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
          <td mat-cell *matCellDef="let row">{{ row.type }}</td>
        </ng-container>
        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Version</th>
          <td mat-cell *matCellDef="let row">{{ row.version }}</td>
        </ng-container>
        <ng-container matColumnDef="displayName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ersteller</th>
          <td mat-cell *matCellDef="let row">{{ row.displayName }}</td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Erstellt</th>
          <td mat-cell *matCellDef="let row">{{ row.createdDate | date }}</td>
        </ng-container>
        <ng-container matColumnDef="lastUpdatedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Aktualisiert</th>
          <td mat-cell *matCellDef="let row">{{ row.lastUpdatedDate | date }}</td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Beschreibung</th>
          <td mat-cell
              *matCellDef="let row">{{ row.description?.length > 16 ? (row.description | slice : 0 : 16) + '...' : row.description }}
          </td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let row">
            <section class="action-menu flexbox small-gap">
              <button mat-icon-button [matTooltip]="'Aktionen'" [matMenuTriggerFor]="menu" class="open-context-menu">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item
                        [disabled]="!canDeleteDocument(row)"
                        (click)="deleteDocument(row.id)"
                        class="warn">
                  <mat-icon color="warn">delete</mat-icon>
                  <span>Löschen</span>
                </button>
                <button mat-menu-item (click)="newDocumentVersion(row)" [disabled]="!canUpdateDocument(row)">
                  <mat-icon>edit</mat-icon>
                  <span>Neue Version</span>
                </button>
                <button mat-menu-item (click)="downloadDocument(row.id, row.name)" [disabled]="!canOpenDocument(row)">
                  <mat-icon>download</mat-icon>
                  <span>Herunterladen</span>
                </button>
              </mat-menu>
            </section>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="clickDocument()"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            @if (isInitialized$ | async) {
              <div class="small-padding">
                Es sind noch keine Dokumente vorhanden
              </div>
            }
          </td>
        </tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 25, 100]"
        [pageSize]="(userService.euleUser$ | async)?.pagination"
        (page)="onChangePageSize($event.pageSize)"
        aria-label="Select page of users"
      ></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
