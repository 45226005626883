import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
} from '@angular/core';
import { CustomTooltipComponent } from '../components/custom-tooltip/custom-tooltip.component';

@Directive({
  selector: '[euleTooltip]',
  standalone: true,
})
export class CustomTooltipDirective implements OnDestroy {
  /**
   * Tooltip text to be displayed.
   * @type {string | null}
   */
  @Input() euleTooltip: string | null = null;

  /**
   * Position of the tooltip, either 'bottom' or 'top'.
   * @type {'bottom' | 'top'}
   */
  @Input() tooltipPosition: 'bottom' | 'top' = 'top';

  /**
   * X Offset for the tooltip position.
   * @type {number}
   */
  @Input() tooltipPositionOffsetX: number | undefined = 0;

  /**
   * Y Offset for the tooltip position.
   * @type {number}
   */
  @Input() tooltipPositionOffsetY: number | undefined = 0;

  /**
   * Flag to indicate if the tooltip content is HTML.
   * @type {string | boolean}
   */
  @Input() htmlTooltipContent: string | boolean = false;

  /**
   * Fixed width of the tooltip.
   */
  @Input() fixedWidth: string = 'auto';

  /**
   * Indicates whether the tooltip should not wrap.
   */
  @Input() noWrap: boolean = false;

  /**
   * Reference to the tooltip component.
   * @type {ComponentRef<CustomTooltipComponent> | null}
   */
  private componentRef: ComponentRef<CustomTooltipComponent> | null = null;

  /**
   * Reference to the tooltip element.
   * @type {HTMLElement | null}
   */
  private tooltipElement: HTMLElement | null = null;

  /**
   * Flag to track if the host element is hovered.
   * @type {boolean}
   */
  private isHostHovered: boolean = false;

  /**
   * Flag to track if the tooltip element is hovered.
   * @type {boolean}
   */
  private isTooltipHovered: boolean = false;


  /**
   * Constructor to inject necessary dependencies.
   * @param {ElementRef} elementRef - Reference to the host element.
   * @param {ApplicationRef} appRef - Reference to the application.
   * @param {Injector} injector - Injector for creating the tooltip component.
   */
  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {
  }

  /**
   * HostListener for mouseenter event on the host element.
   * Sets the isHostHovered flag to true and shows the tooltip.
   */
  @HostListener('mouseenter')
  onHostMouseEnter(): void {
    this.isHostHovered = true;
    this.showTooltip();
  }

  /**
   * HostListener for mouseleave event on the host element.
   * Sets the isHostHovered flag to false and checks if the tooltip should be destroyed.
   */
  @HostListener('mouseleave')
  onHostMouseLeave(): void {
    this.isHostHovered = false;
    setTimeout(() => this.checkDestroy(), 100); // Delay to allow mouse to move to tooltip
  }

  /**
   * Lifecycle hook to clean up the tooltip when the directive is destroyed.
   */
  ngOnDestroy(): void {
    this.destroyTooltip();
  }

  /**
   * Creates and displays the tooltip component.
   */
  private showTooltip(): void {
    if (this.componentRef !== null) return;

    this.componentRef = createComponent(CustomTooltipComponent, {
      environmentInjector: this.appRef.injector,
      elementInjector: this.injector,
    });
    this.appRef.attachView(this.componentRef.hostView);
    this.tooltipElement = (this.componentRef.hostView as EmbeddedViewRef<HTMLElement>).rootNodes[0] as HTMLElement;

    document.body.appendChild(this.tooltipElement);
    this.setTooltipComponentProperties();
    this.addTooltipEventListeners();
  }

  /**
   * Destroys the tooltip component and cleans up references.
   */
  private destroyTooltip(): void {
    if (this.componentRef !== null) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
      this.tooltipElement = null;
    }
  }

  /**
   * Checks if the tooltip should be destroyed based on hover states.
   */
  private checkDestroy(): void {
    if (!this.isHostHovered && !this.isTooltipHovered) {
      this.destroyTooltip();
    }
  }

  /**
   * Sets the properties of the tooltip component based on the directive inputs.
   */
  private setTooltipComponentProperties(): void {
    if (!this.componentRef) return;

    this.componentRef.instance.tooltip = this.euleTooltip;
    this.componentRef.instance.fixedWidth = this.fixedWidth;
    this.componentRef.instance.tooltipPosition = this.tooltipPosition;
    this.componentRef.instance.useHtml = !!this.htmlTooltipContent;
    this.componentRef.instance.noWrap = this.noWrap;

    const { left, right, bottom, top } = this.elementRef.nativeElement.getBoundingClientRect();
    switch (this.tooltipPosition) {
      case 'top':
        this.componentRef.instance.left = (right - left) / 2 + left + (this.tooltipPositionOffsetX || 0);
        this.componentRef.instance.bottom = window.innerHeight - top + 8 + (this.tooltipPositionOffsetY || 0) + 'px';
        break;
      case 'bottom':
        this.componentRef.instance.left = (right - left) / 2 + left;
        this.componentRef.instance.top = bottom + 8 + (this.tooltipPositionOffsetY || 0) + 'px';
        break;
    }
  }

  /**
   * Adds event listeners to the tooltip element to track hover state.
   */
  private addTooltipEventListeners(): void {
    if (!this.tooltipElement) return;

    this.tooltipElement.addEventListener('mouseenter', () => {
      this.isTooltipHovered = true;
    });

    this.tooltipElement.addEventListener('mouseleave', () => {
      this.isTooltipHovered = false;
      setTimeout(() => this.checkDestroy(), 100); // Delay to ensure smooth transition
    });
  }
}
