import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { Pagination, TaskData } from '@eeule/eeule-shared';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, catchError, forkJoin, map, mergeMap, of, takeUntil } from 'rxjs';
import { timeStampToDate } from '../../../../util/date.helper';
import { getDisciplineEnumValue } from '../../../../util/enum.helper';
import { BaseComponent } from '../../../core/components/base/base.component';
import { GeneralTitleComponent } from '../../../core/components/general-title/general-title.component';
import { SearchBarComponent } from '../../../core/components/search-bar/search-bar.component';
import { TypeSafeMatCellDef } from '../../../core/directives/TypeSafeMatCellDef';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { CsvService, ExportAsCsvData } from '../../../core/services/export/csv.service';
import { ProjectService } from '../../../core/services/project.service';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { TaskService } from '../../../core/services/task.service';
import { ProjectUserDisplay, UserService } from '../../../core/services/user.service';
import { DisciplineEnum } from '../../../enums/Discipline.enum';
import { PriorityEnum } from '../../../enums/Priority.enum';
import { TaskStatusEnum } from '../../../enums/TaskStatus.enum';
import { TaskTypeEnum } from '../../../enums/TaskType.enum';
import { FirebaseDocumentData } from '../../../types/firebase-types';
import {
  IndicatorTaskDialogComponent,
  IndicatorTaskDialogComponentConfig,
} from '../../components/indicator-task-dialog/indicator-task-dialog.component';
import { PermissionService } from '../../../core/services/permission.service';
import { CustomTooltipDirective } from '../../../core/directives/custom-tooltip.directive';

@Component({
  selector: 'eule-audit-tasks-page',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    GeneralTitleComponent,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    SearchBarComponent,
    TypeSafeMatCellDef,
    CustomTooltipDirective,
  ],
  templateUrl: './audit-tasks-page.component.html',
  styleUrl: './audit-tasks-page.component.scss',
})
export class AuditTasksPageComponent extends BaseComponent implements AfterViewInit {
  public displayedColumns: string[] = [
    'select',
    'taskNumber',
    'title',
    'type',
    'discipline',
    'responsibleId',
    'lph',
    'priority',
    'status',
    'updateTime',
  ];
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public priorityTypeEnum: typeof PriorityEnum | any = PriorityEnum;
  public taskTypeEnum: typeof TaskTypeEnum | any = TaskTypeEnum;
  public priorityEnum: typeof PriorityEnum | any = PriorityEnum;
  public taskStatusEnum: typeof TaskStatusEnum | any = TaskStatusEnum;
  public disciplineEnum: typeof DisciplineEnum = DisciplineEnum;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  public dataSource: MatTableDataSource<TaskData> = new MatTableDataSource();
  public tasks: TaskData[] = [];
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public filterValue: string = '';
  public projectId: string | undefined;
  public selection = new SelectionModel<TaskData>(true, []);


  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  constructor(
    public  _permissionService: PermissionService,
    public userService: UserService,
    private _analyticsService: AnalyticsService,
    private _router: Router,
    private _taskService: TaskService,
    private _taskDialog: MatDialog,
    private _projectService: ProjectService,
    private cdr: ChangeDetectorRef,
    private _csvService: CsvService,
    private _snackbarService: SnackbarService
  ) {
    super();
    this._analyticsService.sendPageView('audit-tasks-page');
    //fixme just a workaround, project should be emitted elsewhere at this point
    this.projectId = this._projectService.project$.value?.id;
    if (!this.projectId) {
      const urlProjectPartArr: string[] = this._router.url.split('/');
      const projectIndex: number = urlProjectPartArr.indexOf('project');
      this.projectId = urlProjectPartArr[projectIndex + 1] || '';
    }

    if (!this.projectId) {
      throw new Error('No Project set');
    }

    this._taskService
      .getLiveAllProjectTasks(this.projectId)
      .pipe(
        // read project User Data for displaying Creator in List
        mergeMap((tasks: TaskData[]) => {
          const arr = tasks.map(_task =>
            this._projectService.getProjectUserDisplay(this.projectId as string, _task.creatorId).pipe(
              map((user: ProjectUserDisplay) => {
                return { ..._task, creatorId: `${user?.firstName || ''} ${user?.lastName || ''}` }; // only for display purposes
              })
            )
          );
          if (!arr?.length) {
            this.isLoading$.next(false);
            this.isInitialized$.next(true);
          }
          return forkJoin(arr);
        }),
        // read project User Data for displaying Responsible in List
        mergeMap((tasks: TaskData[]) => {
          const arr = tasks.map(_task => {
            if (_task.responsibleId && this.projectId) {
              return this._projectService.getProjectUserDisplay(this.projectId, _task.responsibleId).pipe(
                map((user: ProjectUserDisplay) => {
                  return { ..._task, responsibleId: `${user?.firstName || ''} ${user?.lastName || ''}` }; // only for display purposes
                })
              );
            } else {
              return of(_task);
            }
          });
          return forkJoin(arr);
        }),
        catchError(error => {
          this.isLoading$.next(false);
          throw new Error(error);
        }),
        takeUntil(this.stop$)
      )
      .subscribe((_tasks: TaskData[]) => {
        this.tasks = _tasks;
        this.dataSource.data = this.tasks.map((task: TaskData) => {
          const _createTime: Timestamp | undefined = (task as FirebaseDocumentData<TaskData>).createTime;
          const _updateTime: Timestamp | undefined = (task as FirebaseDocumentData<TaskData>).updateTime;
          return {
            ...task,
            discipline: getDisciplineEnumValue(task.discipline) as DisciplineEnum,
            createTime: timeStampToDate(_createTime),
            updateTime: timeStampToDate(_updateTime),
          };
        });
        this.isLoading$.next(false);

        if (this.isInitialized$.value) return;

        this.isInitialized$.next(true);
      });
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.dataSource.sort.sort({ id: 'taskNumber', start: 'asc', disableClear: false });
    }
    this.cdr.detectChanges();
  }

  getDiscipline(row: TaskData): DisciplineEnum | undefined {
    return row.discipline;
  }

  applyFilter(_filterValue: string) {
    this.filterValue = _filterValue;
    this.dataSource.filter = _filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public apply() {}

  public onNewTask() {
    this._analyticsService.sendEvent('button_click', {
      label: 'audit-tasks-page_new-task',
    });
    this._taskDialog.open<IndicatorTaskDialogComponent, IndicatorTaskDialogComponentConfig, string>(IndicatorTaskDialogComponent, {
      width: '85vw',
      maxWidth: '85vw',
      height: '90vh',
      data: { id: null },
    });
  }

  onTaskKeyDown(event: KeyboardEvent, task: TaskData) {
    event.stopPropagation();
    if (event.key === 'Enter') {
      this.onTaskClick(task);
    }
  }

  public onTaskClick(_task: TaskData) {
    this._analyticsService.sendEvent('button_click', {
      label: 'audit-tasks-page_open-task',
    });
    this._taskDialog.open<IndicatorTaskDialogComponent, IndicatorTaskDialogComponentConfig, string>(IndicatorTaskDialogComponent, {
      width: '85vw',
      maxWidth: '85vw',
      height: '90vh',
      data: { id: _task.id },
    });
  }

  public onChangePageSize(pageSize: Pagination | number) {
    this.userService.updateUser(this.userService.euleUser$.value!.id, { pagination: pageSize as Pagination });
  }

  public onExport() {
    const dataToExport: ExportAsCsvData = {
      header: [
        { id: 'taskNumber', title: 'Nummer' },
        { id: 'title', title: 'Titel' },
        { id: 'type', title: 'Typ' },
        { id: 'creatorId', title: 'Ersteller' },
        { id: 'discipline', title: 'Disziplin' },
        { id: 'responsibleId', title: 'Verantwortlich' },
        { id: 'leistungsPhasen', title: 'Lph' },
        { id: 'priority', title: 'Priorität' },
        { id: 'status', title: 'Status' },
        { id: 'date', title: 'Erstellt' },
        { id: 'description', title: 'Erläuterung' },
      ],
      rows: this.selection.selected.map((task: TaskData) => {
        return {
          taskNumber: task.taskNumber,
          title: task.title,
          type: this.taskTypeEnum[task.type],
          creatorId: task.creatorId,
          discipline: task.discipline,
          responsibleId: task.responsibleId,
          leistungsPhasen: task.leistungsPhasen,
          priority: this.priorityEnum[task.priority],
          status: this.taskStatusEnum[task.status],
          date: timeStampToDate(task.date),
          description: task.description,
          // this._projectService.getScenariosByIds();
        };
      }),
      fileName: 'eeule-Aufgaben'
    };
    this._csvService.downloadCsv(dataToExport).subscribe({
      next: () => this._snackbarService.showMessage('Download abgeschlossen', 'success'),
      error: err => this._snackbarService.showErrorMessage('Fehler beim Download:', err),
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TaskData): string {
    if (!row) {
      return `Alle ${this.isAllSelected() ? 'deselektieren' : 'selektieren'}`;
    }
    return `${row.title} ${this.selection.isSelected(row) ? 'deselektieren' : 'selektieren'}`;
  }
}
