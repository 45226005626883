import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import {
  AppConfigStripe,
  DgnbSystem,
  MixedUsageProfile,
  Pagination,
  Project,
  ProjectData,
  RoleAssignment,
  User,
} from '@eeule/eeule-shared';
import { doc, Timestamp } from 'firebase/firestore';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  forkJoin,
  from,
  map,
  mergeMap,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { getUsageProfileEnumValue } from '../../../util/enum.helper';
import { BaseComponent } from '../../core/components/base/base.component';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '../../core/components/confirm-dialog/confirm-dialog.component';
import { GeneralTitleComponent } from '../../core/components/general-title/general-title.component';
import { SearchBarComponent } from '../../core/components/search-bar/search-bar.component';
import { TypeSafeMatCellDef } from '../../core/directives/TypeSafeMatCellDef';
import { AnalyticsService } from '../../core/services/analytics/analytics.service';
import {
  AppConfigService,
  AppConfigStripeSystemUsageProfileConfiguration,
} from '../../core/services/appConfig/app-config.service';
import { AuthService } from '../../core/services/auth-christian/auth.service';
import { IndicatorService } from '../../core/services/indicator.service';
import { ProjectService } from '../../core/services/project.service';
import { SnackbarService } from '../../core/services/snackbar.service';
import { UsageProfileService } from '../../core/services/usage-profiles/usage-profile.service';
import { UserService } from '../../core/services/user.service';
import { UsageProfileEnum } from '../../enums/UsageProfile.enum';
import { NewProjectDialogComponent } from '../components/new-project-dialog/new-project-dialog.component';
import {
  SubscriptionSelectionDialogComponent,
  SubscriptionSelectionDialogConfig,
} from '../components/subscription-selection-dialog/subscription-selection-dialog.component';
import { NewProjectHelperService } from '../services/new-project-helper.service';
import { RoleService } from '../../core/services/role.service';
import { FirebaseService } from '../../core/services/firebase.service';
import { PermissionService } from '../../core/services/permission.service';
import { CustomTooltipDirective } from '../../core/directives/custom-tooltip.directive';

export type ProjectTableData = Project & {
  projectOwners: string[];
  usageProfileKeys: UsageProfileEnum[];
  currentUserCanDelete: boolean;
};

export type ProjectX = Partial<Project> & {owners: User[]};

@Component({
  selector: 'eule-project-list',
  standalone: true,
  imports: [
    CommonModule,
    GeneralTitleComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    SearchBarComponent,
    MatCardModule,
    MatTooltip,
    TypeSafeMatCellDef,
    CustomTooltipDirective,
  ],
  templateUrl: './project-list.component.html',
  styleUrl: './project-list.component.scss',
})
export class ProjectListComponent extends BaseComponent implements OnInit, AfterViewInit {
  public displayedColumns: string[] = ['name', 'number', 'systemType', 'projectOwners', 'date', 'addressCity', 'usageProfiles', 'actions'];
  public dataSource: MatTableDataSource<ProjectTableData> = new MatTableDataSource<ProjectTableData>();
  public filterValue: string = '';
  public deletingInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public appConfigStripe$ = this._appConfigService.getLiveAppConfigStripe().pipe(takeUntil(this.stop$), take(1), shareReplay(1));

  protected readonly getUsageProfileEnumValue = getUsageProfileEnumValue;

  private _refresh$: BehaviorSubject<null> = new BehaviorSubject(null);
  private _authUserId: string | undefined = this._authService.getAuth().currentUser?.uid;

  @ViewChild(MatPaginator) paginator!: MatPaginator | null;
  @ViewChild(MatSort) sort!: MatSort | null;

  constructor(
    public _permissionService: PermissionService,
    public projectService: ProjectService,
    public userService: UserService,
    private _analyticsService: AnalyticsService,
    private _router: Router,
    private _newProjectDialog: MatDialog,
    private _subscriptionSelectionDialog: MatDialog,
    private _indicatorService: IndicatorService,
    private _usageProfilesService: UsageProfileService,
    private _snackbarService: SnackbarService,
    private analyticsService: AnalyticsService,
    private _newProjectHelperService: NewProjectHelperService,
    private _authService: AuthService,
    private _confirmDialog: MatDialog,
    private _appConfigService: AppConfigService,
    private _roleService: RoleService,
    private _firebaseService: FirebaseService,
  ) {
    super();
    this._analyticsService.sendPageView('project-list-page');
  }

  ngOnInit() {
    this._loadProjects();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public onChangePageSize(pageSize: Pagination | number) {
    this.userService.updateUser(this.userService.euleUser$.value!.id, { pagination: pageSize as Pagination });
  }

  public newProject(): void {
    this.analyticsService.sendEvent('button_click', { label: 'project-list_newProject' });
    const newProjectDialogRef = this._newProjectDialog.open<NewProjectDialogComponent, Project, Project | undefined>(NewProjectDialogComponent, {
      width: '40vw',
      maxWidth: '40vw',
      height: '80vh',
    });

    newProjectDialogRef
      .afterClosed()
      .pipe(
        switchMap((newProjectDialogResult: Project | undefined) => combineLatest([of(newProjectDialogResult), this.appConfigStripe$])),
        switchMap(([newProjectDialogResult, stripe]: [Project | undefined, AppConfigStripe]) => {
          if (!newProjectDialogResult) {
            return EMPTY; // normaler geplanter Abbruch, wenn der Dialog abgebrochen bzw. ohne Ergebnis geschlossen wurde
          }
          if (!newProjectDialogResult?.dgnbSystem) {
            throw new Error('Project DGNB System not set');
          }
          if (!newProjectDialogResult?.id) {
            throw new Error('Project ID not set');
          }
          if (newProjectDialogResult?.bgfBigger5000 === newProjectDialogResult?.bgfSmaller5000) {
            throw new Error('bgfBigger5000 and bgfSmaller5000 have the same value. One must be true, the other false');
          }
          if (newProjectDialogResult?.withDeconstruction === newProjectDialogResult?.withoutDeconstruction) {
            throw new Error('withDeconstruction and withoutDeconstruction have the same value. One must be true, the other false');
          }

          if (newProjectDialogResult && this._authUserId) {
            this.isLoading$.next(true);
            const projectOwnerId: string = crypto.randomUUID();
            const _project: Project = {
              ...newProjectDialogResult,
              // softDeleted muss mit dem Stripeprozess abgestimmt werden.
              // Ein Projekt sollte mit diesem Feld erstellt werden und nach erfolgreicher Zahlung von der Stripe Webhook wieder aktiviert werden
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              buildingDescriptionGroups: this._newProjectHelperService.getBuildingDescriptionGroups(),
              usageProfiles: newProjectDialogResult.usageProfiles.map((profile: MixedUsageProfile) =>
                this._newProjectHelperService.getUsageProfileWithDescriptionGroup(profile),
              ),
              certificationDescriptionGroups: this._newProjectHelperService.getCertificationDescriptionGroups(),
              imagesDescriptionGroups: this._newProjectHelperService.getImagesDescriptionGroups(),
              projectOwner: projectOwnerId,
              leistungsPhasen: new Array(9).fill(null),
              qng: false,
              euTaxonomy: false,
            };
            // Only if Stripe is active, set the softDeleted flag.
            if (stripe.active) {
              _project.softDeleted = Timestamp.fromDate(new Date());
            }

            if (!_project.usageProfiles.length) {
              this._snackbarService.showErrorMessage('Nutzungsprofilerstellung gescheitert');
              return EMPTY;
            }
            return combineLatest([of(_project), this.projectService.createProject(_project), this.appConfigStripe$]);
          }
          return EMPTY;
        }),
        tap(([proj]: [Project, void, AppConfigStripe]) => {
          const roleAssignmentToSet: RoleAssignment = {
            id: this._authUserId!,
            roles: [doc(this._firebaseService.firestore, 'roles', 'owner'), doc(this._firebaseService.firestore, 'roles', 'viewer')],
          };
          this._roleService.setProjectRoleAssignment(proj.id, this._authUserId!, roleAssignmentToSet);
        }),
        tap(([proj]: [Project, void, AppConfigStripe]) =>
          this._indicatorService.cloud_copyDgnbSystemToProjectPreCheck(
            `dgnbSystems/${proj.dgnbSystem}`,
            `projects/${proj.id}/preCheckScenarios/${crypto.randomUUID()}`,
          ),
        ),
        switchMap(([proj]: [Project, void, AppConfigStripe]) => {
          return combineLatest([of(proj), this.projectService.addUserToProject(proj.id, this._authUserId!), this.appConfigStripe$]);
        }),
        switchMap(([proj, , stripe]: [Project, void, AppConfigStripe]) =>
          combineLatest([of(proj), this.userService.addProjectToUser(proj.id, this._authUserId!), of(stripe)]),
        ), // TODO: STRIPE dieser schritt muss nach cloud function von stripe
        switchMap(([proj, , stripe]: [Project, void, AppConfigStripe]) =>
          combineLatest([
            of(proj),
            this.userService.addProjectToUser(proj.id, this._authUserId!),
            of(stripe),
            from(this._appConfigService.getAppConfigStripeSystemUsageProfileConfiguration(proj.dgnbSystem, proj.usageProfiles[0].usageProfile)),
          ]),
        ), // TODO: STRIPE dieser schritt muss nach cloud function von stripe
        switchMap(
          ([proj, _, stripeConfig, stripeSystemCongifuration]: [Project, void, AppConfigStripe, AppConfigStripeSystemUsageProfileConfiguration | null]) => {
            if (stripeConfig.active && stripeSystemCongifuration?.pricingTableId) {
              return this._subscriptionSelectionDialog
                .open<SubscriptionSelectionDialogComponent, SubscriptionSelectionDialogConfig, unknown | undefined>(SubscriptionSelectionDialogComponent, {
                  width: '80vw',
                  maxWidth: '80vw',
                  height: '90vh',
                  disableClose: true,
                  data: {
                    clientReferenceId: `${this.userService.euleUser$.value!.id}_${proj.id}`,
                    email: this.userService.euleUser$.value!.email,
                    projectId: proj.id,
                    pricingTableId: stripeSystemCongifuration.pricingTableId,
                  },
                })
                .afterClosed()
                .pipe(
                  map(() => [proj, _] as [Project, void]) // explizit typisieren
                );
            } else {
              this._snackbarService.showErrorMessage('Fehler in Projekterstellung');
              return of([proj, _] as [Project, void]);
            }
          }
        ),
        // ATTENTION: all code after the stripe dialog is irrelevant since stripe routes to eeule app via link after successful payment, which kill the old page
        catchError(error => {
          this.isLoading$.next(false);
          throw new Error('Error:', error);
        })
      )
      .subscribe();
  }

  applyFilter(_filterValue: string) {
    this.filterValue = _filterValue;
    if (this.dataSource) {
      this.dataSource.filter = _filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      } else {
        new Error('No DataSource for Table');
      }
    }
  }

  public clickProjekt(project: ProjectData) {
    this._router.navigate(['/intern/project', project.id]).catch(error => console.error(error));
  }

  public onDelete(projectId: string, event: Event) {
    event.stopPropagation();
    this._confirmDialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
        width: '360px',
        data: { dynamicContent: 'Projekt löschen' },
      })
      .afterClosed()
      .pipe(
        take(1),
        switchMap(takeAction => {
          if (!takeAction) return EMPTY;
          this.deletingInProgress$.next(true);
          return from(this.projectService.cloudFunctionSoftDeleteProject(projectId));
        }),
        tap(() => {
          this.deletingInProgress$.next(false);
          this._refresh$.next(null);
        }),
        catchError(err => {
          this.deletingInProgress$.next(false);
          console.error('Error deleting Project', err);
          return err;
        })
      )
      .subscribe();
  }

  private _loadProjects() {
    if (!this._authUserId) {
      throw new Error('Auth User not found');
    }
    this.isLoading$.next(true);

    // Observable that retrieves the projects of the authenticated user.
    const userProjects$: Observable<Project[]> = this._refresh$.pipe(
      // Switches to the observable that retrieves the authenticated user.
      switchMap(() => this.userService.getUser(this._authUserId!)),
      // Switches to the observable that retrieves the projects of the authenticated user.
      switchMap((user: User) => {
        if (user.projectIds?.length) {
          return this.projectService.getProjectsByIds(user.projectIds);
        } else {
          return of([]);
        }
      }),
      // Additional Security Method to not show softDeleted Projects
      map((proj: Project[]) => proj.filter(proj => !proj.softDeleted)),
      // read project User Data for displaying in List
      mergeMap((projects: Project[]) => {
        if (!projects?.length) return of([]);
        const projectWithOwners$ = projects
          .map(proj =>
          this.projectService.getAllProjectUsersDisplay(proj.id).pipe(
            mergeMap(users => {
              if (!users?.length) return of([]);
              const usersWithRoleAssignments$ = users.map(user => {
                return this._roleService.getProjectRoleAssignmentByUserId(proj.id, user.id).pipe(
                  map((roleAssignment) => {
                    return { ...user, roleAssignment: roleAssignment?.data};
                  }),
                );
              });
              return forkJoin(usersWithRoleAssignments$);
            }),
            map((usersWithRoleAssignments) => {
              return {
                ...proj,
                owners: usersWithRoleAssignments.filter(user => {
                  return user.roleAssignment?.roles?.some(role => role.id === 'owner');
                })
              };
            }),
          ),
        );
        return forkJoin(projectWithOwners$);
      }),
    );
    combineLatest([
      userProjects$.pipe(
        mergeMap(projects => {
          if (!projects?.length) return of([]);
          const arr: Observable<DgnbSystem>[] = projects
            .filter(project => project.dgnbSystem?.length)
            .map(proj => {
              return this._indicatorService.getDgnbSystem(proj.dgnbSystem);
            });
          return forkJoin(arr).pipe(
            map(dgnbSystems => {
              return projects.map(project => {
                return {
                  ...project,
                  resolvedDgnbSystem: dgnbSystems.find(dgnbSystem => dgnbSystem.id === project.dgnbSystem),
                };
              });
            }),
          );
        }),
        mergeMap(projects => {
          const projectWithRights = projects.map(project => {
            return this._permissionService.getUserProjectPermissions(this._authUserId!, project.id).pipe(
              map(permissions => {
                return {
                  ...project,
                  currentUserCanDelete: !!permissions?.data?.rights.includes('project_view_soft_delete'),
                };
              }),
            );
          });

          if (!projectWithRights.length) return of([]);
          return forkJoin(projectWithRights);
        }),
      ),
      this._usageProfilesService.getUsageProfiles(),
    ]).pipe(
      catchError(error => {
        this._snackbarService.showErrorMessage('Beim Initialisieren der Daten ist ein Fehler aufgetreten.');
        return throwError(() => error);
      }),
      takeUntil(this.stop$),
    ).subscribe(([projects, usageProfiles]) => {
      this.dataSource.data =
        projects.map(project => {
          const _project: ProjectX = project as unknown as ProjectX;
          return {
            ...project,
            projectOwners: _project.owners.map(owner => {
              if (owner.firstName && owner.lastName) {
                return `${owner.firstName} ${owner.lastName}`;
              }
              return owner.email || '---';
            }) || [],
            usageProfileKeys:
              usageProfiles.data
                ?.filter(up => {
                  return project.usageProfiles.map(pup => pup.usageProfile).some(pup => pup === up.id);
                })
                .map(up => up.name as UsageProfileEnum) || [],
          };
        }) || [];
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading$.next(false);
    });
  }
}
