<div class="audit-tasks">
  <eule-general-title [title]="'Aufgaben'">
    <div class="flexbox smaller-gap align-center">
      @if (this.selection.selected.length) {
        <div [euleTooltip]="_permissionService.hasRights('project_export_data')
              ? null
              : 'Sie besitzen nicht die benötigten Rechte, um Projekt-Aufgaben zu exportieren.'"
             [noWrap]="true"
             [tooltipPositionOffsetY]="6">
          <button mat-raised-button color="primary" class="btn vertical no-shadow"
                  [disabled]="!_permissionService.hasRights('project_export_data')"
                  (click)="onExport()" data-cy="button-audit-task-page-new-task">
            <mat-icon>download</mat-icon>
            Exportieren
          </button>
        </div>
      }
      <div [euleTooltip]="_permissionService.hasRights('project_create_task')
              ? null
              : 'Sie besitzen nicht die benötigten Rechte, um Projekt-Aufgaben zu erstellen.'"
           [noWrap]="true"
           [tooltipPositionOffsetY]="6">
        <button mat-raised-button color="primary" class="btn vertical no-shadow"
                [disabled]="!_permissionService.hasRights('project_create_task')"
                (click)="onNewTask()" data-cy="button-audit-task-page-new-task">
          <mat-icon>add</mat-icon>
          Aufgabe
        </button>
      </div>
      <div class="v-divider"></div>
      <eule-search-bar id="search-field" [(search)]="filterValue"
                       (searchChange)="applyFilter($event)"></eule-search-bar>
    </div>
  </eule-general-title>

  <mat-card class="custom-card task-table-card overflow-hidden">
    <mat-card-content class="small-padding task-table-card-content custom-scrollbar">
      @if (isLoading$ | async) {
        <div class="loading-overlay covering h-100-percent card-background">
          <mat-spinner diameter="50" />
        </div>
      }
      <table mat-table [dataSource]="dataSource" matSort class="differ-rows">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- Progress Column -->
        <ng-container matColumnDef="taskNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nr.</th>
          <td mat-cell *matCellDef="let row">{{ row.taskNumber }}</td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Titel</th>
          <td mat-cell *matCellDef="let row">
            <a class="primary lighter cursor-pointer"
               tabindex="0"
               role="button"
               (click)="$event.stopPropagation(); onTaskClick(row)"
               (keydown)="onTaskKeyDown($event, row)
">
              {{ row.title }}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
          <td mat-cell *matCellDef="let row">{{ taskTypeEnum[row.type] }}</td>
        </ng-container>
        <!--      <ng-container matColumnDef="creatorId">-->
        <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ersteller</th>-->
        <!--        <td mat-cell *matCellDef="let row">{{ row.creatorId }}</td>-->
        <!--      </ng-container>-->
        <ng-container matColumnDef="discipline">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Disziplin</th>
          <td mat-cell *matCellDef="let row">{{ getDiscipline(row) }}</td>
        </ng-container>
        <ng-container matColumnDef="responsibleId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Verantwortlich</th>
          <td mat-cell *matCellDef="let row">{{ row.responsibleId }}</td>
        </ng-container>
        <ng-container matColumnDef="lph">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Lph</th>
          <td mat-cell *matCellDef="let row">{{ row.leistungsPhasen }}</td>
        </ng-container>
        <ng-container matColumnDef="priority">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Priorität</th>
          <td mat-cell *matCellDef="let row">{{ priorityTypeEnum[row.priority] }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let row">{{ taskStatusEnum[row.status] }}</td>
        </ng-container>
        <!--      <ng-container matColumnDef="createTime">-->
        <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header>Erstellt</th>-->
        <!--        <td mat-cell *matCellDef="let row">-->
        <!--          @if(row.createTime) {-->
        <!--          {{ row.createTime | date : 'dd.MM.yyyy' }}-->
        <!--          }-->
        <!--        </td>-->
        <!--      </ng-container>-->
        <ng-container matColumnDef="updateTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Aktualisiert</th>
          <td mat-cell *matCellDef="let row">
            @if (row.updateTime) {
              {{ row.updateTime | date : 'dd.MM.yyyy' }}
            }
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Aktionen</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button [matTooltip]="'Exportieren'" (click)="onExport(row, $event)" class="close-button smaller-button">
              <mat-icon>download</mat-icon>
            </button>
          </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            @if (isInitialized$ | async) {
              <div class="small-padding">Es sind noch keine Aufgaben vorhanden</div>
            }
          </td>
        </tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 25, 100]"
        [pageSize]="(userService.euleUser$ | async)?.pagination"
        (page)="onChangePageSize($event.pageSize)"
        aria-label="Select page of users"
      ></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
