import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BaseComponent } from '../../../../core/components/base/base.component';
import { CommaDecimalInputComponent } from '../../../../core/components/decimal-comma/decimal-comma.component';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';
import { ProjectService } from '../../../../core/services/project.service';
import { UsageProfileEnum } from '../../../../enums/UsageProfile.enum';
import { Tooltip } from '../project-info-page/project-info-page.component';
import { UsageProfileParametersComponent } from './components/usage-profile-parameters/usage-profile-parameters.component';
import { BuildingParametersService } from './services/buildingParametersService';
import { CustomTooltipDirective } from '../../../../core/directives/custom-tooltip.directive';

@Component({
  selector: 'eule-tab-building-parameters',
  standalone: true,
  imports: [
    CommaDecimalInputComponent,
    CommonModule,
    CustomTooltipDirective,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
    UsageProfileParametersComponent,
  ],
  templateUrl: './tab-building-parameters.component.html',
  styleUrl: './tab-building-parameters.component.scss',
})
export class TabBuildingParametersComponent extends BaseComponent {
  @Input({ required: true }) buildingParametersFormGroup!: FormGroup;
  public usageProfileEnum: typeof UsageProfileEnum = UsageProfileEnum;

    public tooltips: Tooltip = {
      hasMixedUsageProfiles: `Mischnutzung
      Befinden sich im betrachteten Gebäude mehrere unterschiedliche Nutzungen, muss überprüft werden, welches Nutzungsprofil anzuwenden ist. Grundlage für die Einschätzung, ob die Zertifizierung nach MIX23 stattfinden muss, bildet die DGNB Bemessungsfläche.
      Es wird empfohlen die Einstufungen unterschiedlicher Nutzungen mit der DGNB Geschäftsstelle abzustimmen.
      Weiterführende Informationen finden Sie in dem Dokument Anwendungsregeln zur Mischnutzung, Version 23 (MIX23) von der DGNB.`,
      sideUsage: `Eine oder mehrere Nutzungen, die einem anderen Nutzungsprofil als der Hauptnutzung zugeordnet werden und deren Flächenanteil an der gesamten DGNB Bemessungsfläche ≥ 15% beträgt, wird als Nebennutzung bezeichnet. Die Flächen einer Nebennutzung müssen mit dem entsprechenden Nutzungsprofil bewertet werden. `,
      mainUsage: `Das Nutzungsprofil mit dem größten Flächenanteil an der gesamten DGNB Bemessungsfläche wird als Hauptnutzung bezeichnet. Ist die Einstufung nicht eindeutig möglich, ist die Hauptnutzung festzulegen und die Entscheidung zu begründen.`,
      flaechenberechnungNachDin277: `<h3>Flächenberechnung nach DIN 277</h3>
      <p><strong>BGF(R) = KGF(R) + NRF(R)</strong></p>
      <p><strong>NRF(R) = NUF(R) + TF(R) + VF(R)</strong></p>
      <ul>
      <li>BGF: Brutto-Grundfläche</li>
      <li>KGF: Konstruktions-Grundfläche</li>
      <li>NRF: Netto-Raumfläche</strong></li>
      <li>NUF: Nutzungsfläche</li>
      <li>TF : Technikfläche</li>
      <li>VF : Verkehrsfläche</li></ul>`,
      bemessungsflaeche: `<h3>DGNB Bemessungsfläche</h3>
      <p><strong>A<sub>DGNB</sub> = NUF<sub>a</sub> - NUF<sub>a,7,4</sub> + VF<sub>a,9,1</sub></strong></p>
      <ul><li>A<sub>DGNB</sub>: DGNB Bemessungsfläche</li>
      <li>NUF<sub>a</sub>: Nutzungsfläche nach DIN277</li>
      <li>NUF<sub>a,7,4</sub>: Fahrzeugabstellfläche nach DIN 277</li>
      <li>VF<sub>a,9,1</sub>: Verkehrsfläche Flure und Hallen nach DIN277</li></ul>
      <p>Weiterführende Informationen finden Sie in dem Dokument Anwendungsregeln zur Mischnutzung, Version 23 (MIX23) von der DGNB.</p>`,
    };

  get usageProfiles(): FormArray {
    return this.buildingParametersFormGroup.get('usageProfiles') as FormArray;
  }

  public constructor(
    private _analyticsService: AnalyticsService,
    public projectService: ProjectService,
    public buildingParametersService: BuildingParametersService
  ) {
    super();
    this._analyticsService.sendPageView('tab-building-parameters-component');
  }

  public getTotalform() {
    return this.buildingParametersService.totalForm;
  }

  public getUsageProfilesFormGroupAt(index: number): FormGroup {
    return this.usageProfiles.at(index) as FormGroup;
  }

  public usageProfileChange(profileIndex: number) {
    this.buildingParametersService.calculateUsageProfile(profileIndex);
  }
}
