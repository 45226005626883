<button
  mat-raised-button
  color="primary"
  class="no-shadow"
  (click)="openCustomerPortal()"
  matTooltip="Kundenportal öffnen"
  data-cy="button-stripe-customer-portal"
>
  Kundenportal öffnen
</button>
