<ng-container [formGroup]="imagesDescriptionFormGroup">
  <div class="row tab-content">
    <h2>{{ title }}</h2>
  </div>

  <div class="row">
    <div class="col padding-right">
      <ng-container formArrayName="descriptions">
        @for (description of imagesDescriptions.controls; track description) {
        <ng-container [formGroup]="getDescriptionGroupAt($index)">
          <quill-editor
            class="w-100-percent filled-text-field-background small-radius flat-bottom"
            style="height: 180px; margin-bottom: 4rem"
            formControlName="description"
            [attr.data-cy]="'input-tab-buildingDescription-description-' + $index"
            [placeholder]="getDescriptionTitleAt($index)">
            <div quill-editor-toolbar>
              <eule-custom-quill-editor-toolbar-set
                toolbarSet="custom"
                [allConfigPropsActivated]="true"
                [toolbarConfig]="{
              hasHeadingPicker: false,
              hasImagePicker: false,
            }"
              />
            </div>
          </quill-editor>
        </ng-container>
        }
      </ng-container>
    </div>

    <div class="col padding-right">
      <div class="attachments flexbox direction-column">
        <div class="label default-border-color primary-text flexbox small-gap align-center">
          <div class="attachments-title">Anhänge</div>
            <div [euleTooltip]="_permissionService.hasRights('project_create_document')
                    ? 'Anhang zu Gruppe hinzufügen'
                    : 'Sie besitzen nicht die benötigten Rechte,</br> um Anhänge hinzuzufügen.'"
                 [htmlTooltipContent]="true"
                 [tooltipPositionOffsetY]="6">
            <button
              mat-mini-fab
              color="accent"
              class="no-shadow smaller-button"
              [disabled]="!_permissionService.hasRights('project_create_document')"
              (click)="onOpenAddAttachmentDialog()"
              data-cy="button-tab-imagesDescription-addAttachment"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <div class="row">
          @for(document of groupDocuments$ | async; track document){
          <eule-document-tile [documentId]="document.id" [attr.data-cy]="'document-tile-tab-imagesDescription-' + $index"></eule-document-tile>
          }
        </div>
      </div>
    </div>
  </div>
</ng-container>
